import React from "react";
import { Route, Routes } from "react-router-dom";
import CardItem from "../Components/CardItem";
import { ObjectArray } from "../Components/objOfArticles";
import { guide } from "../UseguideWithSS/objofContent";
import CategoriesList from "../CategoriesList";
import ThirdPageFrame from "../UseguideWithSS/3rdpageFrame";

const IndexRoutes = () => {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [path, setPath] = useState(window.location.pathname);

  // useEffect(() => {
  //   const handleResize = () => setWindowWidth(window.innerWidth);
  //   window.addEventListener("resize", handleResize);
  //   setPath(window.location.pathname);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [setPath]);
  // console.log(path)
  // const pathArray = window.location.pathname.split("/");
  // const mainPath = pathArray[2];
  // console.log(mainPath)

  return (
    <>
      {/* <Router> */}
      {/* {path=="/"?"":()} */}

      <Routes>
        <Route path="/" element={<CategoriesList />}></Route>

        {/* <Route
          exact
          path="/posease-subscription"
          element={<CardItem prop={ObjectArray.PoseaseSubscription} />}
        ></Route>
        <Route
          exact
          path="/Urban-piper-Documents"
          element={<CardItem prop={ObjectArray.UrbanDocuments} />}
        ></Route> */}
        <Route
          exact
          path="/posease-setup"
          element={<CardItem prop={ObjectArray.poseaseSetup} />}
        ></Route>
        <Route
          exact
          path="/product-setup"
          element={<CardItem prop={ObjectArray.productSetup} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order"
          element={<CardItem prop={ObjectArray.Billing} />}
        ></Route>
        <Route
          exact
          path="/receipt-setup"
          element={<CardItem prop={ObjectArray.ReceiptSetup} />}
        ></Route>
        <Route
          exact
          path="/printing"
          element={<CardItem prop={ObjectArray.printing} />}
        ></Route>
        <Route
          exact
          path="/reporting"
          element={<CardItem prop={ObjectArray.Reporting} />}
        ></Route>
        <Route
          exact
          path="/customer-details"
          element={<CardItem prop={ObjectArray.CustomerDetails} />}
        ></Route>
        {/* <Route exact path="/SMS-app" element={<CardItem prop={ObjectArray.SMSApp} />}></Route> */}
        {/* <Route
          exact
          path="/Customer-feedback-app"
          element={<CardItem prop={ObjectArray.CustomerFeedback} />}
        ></Route> */}
        <Route exact path="/inventory-app" element={<CardItem prop={ObjectArray.Inventory} />}></Route>
        {/* <Route exact path="/posease-FAQ" element={<CardItem prop={ObjectArray.PoseaseFAQ} />}></Route> */}
        {/* <Route
          exact
          path="/tablet-mobile-app-setup"
          element={<CardItem prop={ObjectArray.TabletMobileAppSetup} />}
          ></Route> */}
        {/* <Route
          exact
          path="/tablet-mobile-wireless-printer"
          element={<CardItem prop={ObjectArray.TabletMobileWirelessPrinter} />}
          ></Route> */}
        {/* <Route
          exact
          path="/selling-with-tablet-mobile"
          element={<CardItem prop={ObjectArray.SellingWithTabletMobile} />}
        ></Route> */}
        {/* product Setup guide */}
        <Route
          exact
          path="/product-setup/add-edit-delete-product"
          element={
            <ThirdPageFrame prop={guide.productSetup.AddEditDeleteProduct} />
          }
        ></Route>
        <Route
          exact
          path="/product-setup/create-variants"
          element={<ThirdPageFrame prop={guide.productSetup.CreateVariant} />}
        ></Route>
        <Route
          exact
          path="/product-setup/bulk-import-export-products"
          element={
            <ThirdPageFrame prop={guide.productSetup.ImportExportProduct} />
          }
        ></Route>
        <Route
          exact
          path="/product-setup/add-product-categories"
          element={<ThirdPageFrame prop={guide.productSetup.AddProductCat} />}
        ></Route>
        <Route
          exact
          path="/product-setup/create-add-ons-for-products"
          element={<ThirdPageFrame prop={guide.productSetup.CreateAddOns} />}
        ></Route>
        <Route
          exact
          path="/product-setup/manage-multiple-pricing-for-products-using-price-book"
          element={<ThirdPageFrame prop={guide.productSetup.ManagePricing} />}
        ></Route>
        <Route
          exact
          path="/product-setup/limit-a-product-to-a-particular-register-shop"
          element={
            <ThirdPageFrame prop={guide.productSetup.LimitProductToShop} />
          }
        ></Route>
        <Route
          exact
          path="/product-setup/add-Combo-products"
          element={<ThirdPageFrame prop={guide.productSetup.AddComboProduct} />}
        ></Route>
        {/* Receipt Setup */}
        <Route
          exact
          path="/receipt-setup/editing-receipt-header-footer-disabling-print"
          element={<ThirdPageFrame prop={guide.ReceiptSetup.editReceipt} />}
        ></Route>
        <Route
          exact
          path="/receipt-setup/cancel-delete-receipt"
          element={
            <ThirdPageFrame prop={guide.ReceiptSetup.cancelDeleteReceipt} />
          }
        ></Route>
        <Route
          exact
          path="/receipt-setup/enable-strict-receipt-number"
          element={<ThirdPageFrame prop={guide.ReceiptSetup.enableReceiptNo} />}
        ></Route>
        {/* billing/taking order */}
        <Route
          exact
          path="/billing-taking-order/create-kots-to-manage-your-kitchen-orders"
          element={<ThirdPageFrame prop={guide.Billing.manageKitchen} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/table-management-take-orders-by-table"
          element={<ThirdPageFrame prop={guide.Billing.tableManagement} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/shift-openclose-day-end-calculations"
          element={<ThirdPageFrame prop={guide.Billing.shiftOpenClose} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/know-all-about-selling"
          element={<ThirdPageFrame prop={guide.Billing.aboutSelling} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants"
          element={<ThirdPageFrame prop={guide.Billing.printReceipt} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/credit-sales"
          element={<ThirdPageFrame prop={guide.Billing.creditSales} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/hold-bill"
          element={<ThirdPageFrame prop={guide.Billing.holdBill} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/quick-billing-generate-the-bill-in-one-click"
          element={<ThirdPageFrame prop={guide.Billing.quickBilling} />}
        ></Route>
        
        <Route
          exact
          path="/billing-taking-order/apply-coupon-codes-in-billing"
          element={<ThirdPageFrame prop={guide.Billing.applyCodes} />}
        ></Route>
        {/* <Route
          exact
          path="/billing-taking-order/check-for-waiter-active-or-not"
          element={<ThirdPageFrame prop={guide.Billing.checkWaiter} />}
        ></Route> */}
        <Route
          exact
          path="/billing-taking-order/table-swap-in-web-admin-panel"
          element={<ThirdPageFrame prop={guide.Billing.tableSwap} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/manage-tables-based-on-sections"
          element={<ThirdPageFrame prop={guide.Billing.SectionTable} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/split-payments-receive-payments-from-multiple-payment-modes"
          element={<ThirdPageFrame prop={guide.Billing.splitBill} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/adding-delivery-details-for-phone-customers"
          element={<ThirdPageFrame prop={guide.Billing.addingDelivery} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/split-payment-based-on-items-wise"
          element={<ThirdPageFrame prop={guide.Billing.splitBillbasedOnItem} />}
        ></Route>
        <Route
          exact
          path="/billing-taking-order/split-payments-based-upon-customers"
          element={
            <ThirdPageFrame prop={guide.Billing.splitBillBasedOnCustomers} />
          }
        ></Route>
        <Route
          exact
          path="/billing-taking-order/manage-expenses-in-your-store"
          element={
            <ThirdPageFrame prop={guide.Billing.PettyCash} />
          }
        ></Route>
        {/* Posease Setup guide */}
        <Route
          exact
          path="/posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders"
          element={
            <ThirdPageFrame prop={guide.poseaseSetup.WaiterCustomer} />
          }
        ></Route>
        <Route
          exact
          path="/posease-setup/how-to-enable-loyalty-management-with-crm"
          element={
            <ThirdPageFrame prop={guide.poseaseSetup.LoyaltyCrm} />
          }
        ></Route>
        <Route
          exact
          path="/posease-setup/customize-your-pos-with-preferences"
          element={
            <ThirdPageFrame prop={guide.poseaseSetup.CustomizePreferences} />
          }
        ></Route>
        <Route
          exact
          path="/posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud"
          element={
            <ThirdPageFrame prop={guide.poseaseSetup.EmpAccessControl} />
          }
        ></Route>
        <Route
          exact
          path="/posease-setup/add-single-tax-or-multiple-taxes-gst-to-products"
          element={<ThirdPageFrame prop={guide.poseaseSetup.singleMultiTax} />}
        ></Route>
        <Route
          exact
          path="/posease-setup/add-multiple-registersshops-in-one-accounts"
          element={<ThirdPageFrame prop={guide.poseaseSetup.addMultiReg} />}
        ></Route>
        <Route
          exact
          path="/posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc"
          element={
            <ThirdPageFrame prop={guide.poseaseSetup.additionalCharge} />
          }
        ></Route>
        <Route
          exact
          path="/posease-setup/owner-pin-logo-setup"
          element={<ThirdPageFrame prop={guide.poseaseSetup.ownerPin} />}
        ></Route>
        <Route
          exact
          path="/posease-setup/getting-started"
          element={<ThirdPageFrame prop={guide.poseaseSetup.gettingStarted} />}
        ></Route>
        <Route
          exact
          path="/posease-setup/add-tags-to-sale-or-customer"
          element={<ThirdPageFrame prop={guide.poseaseSetup.AddTags} />}
        ></Route>
        <Route
          exact
          path="/posease-setup/create-custom-payment-types"
          element={
            <ThirdPageFrame prop={guide.poseaseSetup.customPaymentType} />
          }
        ></Route>
        <Route
          exact
          path="/posease-setup/employee-access-control-app-user"
          element={<ThirdPageFrame prop={guide.poseaseSetup.EmployeeAccess} />}
        ></Route>
        <Route
          exact
          path="/posease-setup/create-custom-sales-and-customer-fields"
          element={<ThirdPageFrame prop={guide.poseaseSetup.CreateCustomes} />}
        ></Route>
        <Route
          exact
          path="/posease-setup/kitchen-order-tickets-for-multiple-kitchens"
          element={
            <ThirdPageFrame prop={guide.poseaseSetup.KitchenOrderTicket} />
          }
        ></Route>
        <Route
          exact
          path="/posease-setup/create-managers-and-cashiers-for-access-control"
          element={
            <ThirdPageFrame prop={guide.poseaseSetup.CreateManagerCashiers} />
          }
        ></Route>
        <Route
          exact
          path="/posease-setup/get-the-downloadable-version-of-Posease-for-desktop"
          element={<ThirdPageFrame prop={guide.poseaseSetup.DownLoadPosease} />}
        ></Route>
        {/* customer detail */}
        <Route
          exact
          path="/customer-details/view-customer-details-and-export"
          element={
            <ThirdPageFrame prop={guide.customerDetails.exportCustomerDetail} />
          }
        ></Route>
        <Route
          exact
          path="/customer-details/import-customer-data"
          element={
            <ThirdPageFrame prop={guide.customerDetails.importCustomerDetail} />
          }
        ></Route>
        {/* reporting */}
        <Route
          exact
          path="/reporting/kot-report-view-completed-and-cancelled-kots-avoid-fraud"
          element={
            <ThirdPageFrame
              prop={guide.reporting.ViewCompletedCancelledReport}
            />
          }
        ></Route>
        <Route
          exact
          path="/reporting/shift-openclose-report"
          element={
            <ThirdPageFrame prop={guide.reporting.ShiftOpenCloseReport} />
          }
        ></Route>
        <Route
          exact
          path="/reporting/metrics-in-dashboard"
          element={<ThirdPageFrame prop={guide.reporting.MetricsDashboard} />}
        ></Route>
        <Route
          exact
          path="/reporting/export-reports"
          element={<ThirdPageFrame prop={guide.reporting.exportReport} />}
        ></Route>
        {/* printing */}
        <Route
          exact
          path="/printing/add-save-and-remove-printers-desktop-app"
          element={<ThirdPageFrame prop={guide.printing.addSavePrinter} />}
        ></Route>
        <Route
          exact
          path="/printing/avoid-seeing-print-preview"
          element={<ThirdPageFrame prop={guide.printing.avoidPreview} />}
        ></Route>
        <Route
          exact
          path="/printing/select-receipt-size-printer-type"
          element={<ThirdPageFrame prop={guide.printing.SelectReceiptSize} />}
        ></Route>
        <Route
          exact
          path="/printing/static-ip-address-for-mac"
          element={<ThirdPageFrame prop={guide.printing.IpAddressMac} />}
        ></Route>
        <Route
          exact
          path="/printing/static-ip-address-for-printers"
          element={<ThirdPageFrame prop={guide.printing.IpAddressPri} />}
        ></Route>
        {/* inventory */}
        <Route
          exact
          path="/inventory-app/create-ingredients-raw-material"
          element={<ThirdPageFrame prop={guide.Inventory.CreateIngredients} />}
        ></Route>
        <Route
          exact
          path="/inventory-app/add-inventory"
          element={<ThirdPageFrame prop={guide.Inventory.AddInventory} />}
        ></Route>
        <Route
          exact
          path="/inventory-app/inventory-operations"
          element={<ThirdPageFrame prop={guide.Inventory.InventoryOperations} />}
        ></Route>
        <Route
          exact
          path="/inventory-app/create-recipe"
          element={<ThirdPageFrame prop={guide.Inventory.CreateRecipe} />}
        ></Route>
        <Route
          exact
          path="/inventory-app/inward-a-purchased-product-or-raw-material"
          element={<ThirdPageFrame prop={guide.Inventory.Inward} />}
        ></Route>
        <Route
          exact
          path="/inventory-app/manage-Wastage-in-inventory"
          element={<ThirdPageFrame prop={guide.Inventory.ManageWastage} />}
        ></Route>
        <Route
          exact
          path="/inventory-app/adjustment-of-materials"
          element={<ThirdPageFrame prop={guide.Inventory.AdjustmentMaterials} />}
        ></Route>

      </Routes>
      {/* </Router> */}
    </>
  );
};

export default IndexRoutes;
