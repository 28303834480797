export const ObjectArray = {
  productSetup: [
    {
      pageTitle: "Product Setup",
      title: "Add, Edit & Delete products",
      text: "Add new products, edit or delete old products in shop. Click on products in the menu And select Add Products. Enter all the products and save. 3. To delete product",
      path: "product-setup/add-edit-delete-product",
    },
    {
      title: "Create variants for products",
      text: "if you have multiple variant of products, create variants and manage selling with ease. To create variants go to setup > product options. Click on add variant. Enter the variants to be created, enter the price of each variant. For example you sell a small burger for 100 and regular burger at 140. Enter the whole price here,",
      path: "product-setup/create-variants",
    },
    {
      title: "Bulk import & export of products",
      text: "Go ahead import and export your products in Bulk in one shot Select products from the menu. Then click on import.  2. Download the template for product import. 3. Fill product details in the template.",
      path: "product-setup/bulk-import-export-products",
    },
    {
      title: "Add product categories",
      text: "Make product selection easier by creating product categories. Click on setup on left bottom of the screen. Select 'Product categories' Select 'Add product category' to add product categories.",
      path: "product-setup/add-product-categories",
    },
    {
      title: "Create add-ons for products",
      text: "if you have multiple add-ons of products, create add-ons and manage selling with ease. To create add-ons go to setup > product options. Click on addons. Enter the add-ons to be created, enter the price of each add-on. Create add-on",
      path: "product-setup/create-add-ons-for-products",
    },
    {
      title: "Manage Multiple Pricing for Products using Price Book",
      text: "Posease allows its users to manage multiple pricing for the same product using Price Book Feature. Click on Products -Manage Price Book to access the Price Book. Click on Add Price Book button to create a new Price Book. You can restrict the changes made in the price book for a particular register",
      path: "product-setup/manage-multiple-pricing-for-products-using-price-book",
    },
    {
      title: "Limit a product to a particular Register/ Shop",
      text: "If you are running multiple outlets and using multiple registers in Posease, you can optionally choose to limit a product to a particular shop or register. This is useful if you have shop specific products and you do not want such products to be visible across all shops. This can be configured in product setup or by product import. Select the added product and link it to a particular register. ",
      path: "product-setup/limit-a-product-to-a-particular-register-shop",
    },
    {
      title: "Add Combo Products",
      text: "Looking forward to create combos? Create combos to enhance the visibility of products to improve profit. Click on Setup -Product Options -Item Groups to create create item groups for creating combo product. Create Item group by providing the name and select the products under the group. Now assign the it",
      path: "product-setup/add-Combo-products",
    },
    
  ],
  poseaseSetup: [
    {
      pageTitle: "Posease Setup",
      title: "How to enable and use Waiter/Captain App for table orders",
      path:"posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
      text: "Contact Posease team and ask us to enable Waiter app for your account. Please remember Waiter app requires upgrading your Posease subscription. Waiter app needs a central desktop application. Download and extract Posease desktop from dashboard for desktop. Open Posease applica",
    },
    {
      title: "Customize your POS with preferences",
      path: "posease-setup/customize-your-pos-with-preferences",
      text: "Require few customization to enhance usage of Posease? Customized to your requirements by changing the preferences. Click on Setup to view preferences. Click on preferences to customize selling, printing and permission preferences. Selling Preferences. ",
    },
    {
      title: "Employee access control: Cashier/Manager mode (Avoid fraud)",
      path: "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
      text: "The best practice for your business is to never sell in 'Admin' . Create cashier/manager accounts for your employees to avoid fraud. To add/change admin pin, refer the below link https://help.Posease.com/en/article/owner-pin-logo-setup-iipp1e/ Click on setup and select cashiers to add cashier/manager Add cashier/manager name, pin and select the regis",
    },
    {
      title: "Add single tax or multiple taxes (GST) to products",
      path: "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
      text: "Create tax groups, add tax to tax groups, assign to products. Don't worry if tax regulations change, changing tax is a breeze. Click on setup on left bottom of the screen. Select . You can required create taxes, then assign it to tax groups created. Then attached to products in product screen. Create required taxes.",
    },
    {
      title: "Add multiple registers(shops) in one account",
      path: "posease-setup/add-multiple-registersshops-in-one-accounts",
      text: "You can manage all your shops or registers from one admin account. Get a holistic view of all your shops in one place anytime anywhere. You can create a new register as follows. Click on setup on left bottom of the screen. Select Registers. Select Add register to add a new register.",
    },
    {
      title: "Owner pin, Logo setup",
      path: "posease-setup/owner-pin-logo-setup",
      text: "Secure your account from employees by creating a owner pin (Keep it protected). You can also upload a logo that can be printed on the receipt. Click on setup on left bottom of the screen. Select Shop",
    },
    // {
    //   title: "Kitchen Display System Setup",
    //   text: "Start using Kitchen Display System to effectively track the food ready status and stale timing. Ensure to enable the KOT generation from Setup -Preferences. Setup the server IP and the stale time to use KDS. If you see multiple IP addresses in Available networks, make sure you use the IP address of the net",
    // },
    {
      title: "Setup additional charges (parcel, delivery, service charge etc.)",
      path: "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
      text: "All businesses levy additional charges like parcel, delivery, service etc. Create them and add it to the receipts in a click. Click on setup on left bottom of the screen. Click on additional charge and then add additional charge, Enter the additional charge type and details. Then save it. ",
    },
    {
      title: "Getting started",
      path: "posease-setup/getting-started",

      text: "Welcome to Posease. Use the below link to sign into Posease https://web.Posease.com/ You can signup by using one of following two methods Select your type of business, so that we can configure the system accordingly Select Business Type ",
    },
    {
      title: "Add tags to sale or customer",
      path: "posease-setup/add-tags-to-sale-or-customer",
      text: "Tag orders or customers to easily segregate them. To create custom tags to setup -custom fields. Click on add custom field. Type in the name of the tag and select the type of tag, then click on save. ",
    },
    {
      title: "Employee access control: App User",
      path: "posease-setup/employee-access-control-app-user",
      text: "Posease allows you to create an App User ID exclusively for handling apps like Inventory, Analytics, etc. To create an App User ID Click settings -Users -All App Users -Add App User . Enter the User Name and Password . ",
    },
    {
      title: "Create custom sales and customer fields",
      path: "posease-setup/create-custom-sales-and-customer-fields",
      text: "It is always good to collect as much data as possible about the sale and customer. It helps in knowing how the business is performing and buying pattern of customers to improve sales in the future. Click on setup and select custom fields. ​ 2. Enter Payment Type field name and description. Enter Additional Detail",
    },
    {
      title: "Kitchen Order Tickets for multiple kitchens",
      path: "posease-setup/kitchen-order-tickets-for-multiple-kitchens",
      text: "You can generate Kitchen Order Tickets in multiple kitchens based on the product category. To add a new order ticket group click Setup -Product categories -All order ticketgroups -Add order ticket group . To assign the o",
    },
    {
      title: "Create custom payment types",
      path: "posease-setup/create-custom-payment-types",
      text: "With more and more payment providers coming into the market. Customers have many options to pay from. Now create payment types as per requirement in Posease. To create payment types go to setup > custom fields. Click add payment type or custom field.Create custom payment types ",
    },
    {
      title: "Automatic Discount Rules(Offers) To Improve Sales",
      text: "Apply Coupon Codes to provide discounts: Discount rules are used to create coupons for providing discounts in either order level or product level. Coupon codes can be entered in the sell screen to provide discounts. To add a discount rule, click setup -Discount Rules -Add Discount Rule Enter the Coupon code. Select the type of Discount ( Fixed A",
    },
    {
      title: "Apply Product wise discount using coupon codes",
      text: "Product-wise Discounts offer a fixed amount / percentage type discounts for a particular product in the bill amount. To add a discount rule, click setup -Discount Rules -Add Discount Rule Enter the Coupon code. Select the Level of Discount as Product. Add the Register (Shop) for which coupon should be enabled.",
    },
    {
      title: "Apply Order wise Discount using coupon codes",
      text: "Order-wise Discounts offer a fixed amount / percentage type discounts for the total bill amount.. To add a discount rule, click setup -Discount Rules -Add Discount Rule Enter the Coupon code. Select the Level of Discount as Order. Add the Register (Shop) for which coupon should be enabled. ",
    },
    {
      title: "Apply Fixed amount Discounts using coupon codes",
      text: "Fixed amount Discounts offer a fixed amount as discount in the bill amount. They can be applied either on the total bill (Order level Discount) or on a particular product (Product level Discount). To add a discount rule, click setup -Discount Rules -Add Discount Rule Enter the Coupon code. Select the type of Discount as Fixed amount. Add the Reg",
    },
    // {
    //   title: "Analytics app for owners",
    //   text: "Wish to know about your business even better? Please use the analytics app to know about your business from anywhere on world. This is the simple and easy way to monitor the activities of the store. Go to Apps -Analytics in desktop app or Click here to login if you are using the web version. Enable the analytics if not enabled before this process. Click on ",
    // },
    {
      title: "Apply Percentage Discounts using coupon codes",
      text: "Percentage Discounts offer a specified Percentage as discount in the bill amount. They can be applied either on the total bill (Order level Discount) or on a particular product (Product level Discount). To add a discount rule, click setup -Discount Rules -Add Discount Rule Enter the Coupon code. Select the type of Discount as Percentage. Add theApply Percentage Discounts using coupon codes",
    },
    // {
    //   title: "How to enable UPI payments with PayU",
    //   text: "Create QR code for your store in 5 simple steps. Use the payment integration option to upgrade your store and ease of use for customers to make payment. Click on Apps to enable Bharat QR App. Click on Sign up to get Bharat QR to create your own account. Enter a valid email id for communication. How to enable UPI payments with PayU",
    // },
    {
      title: "How to enable loyalty management with CRM",
      path:"posease-setup/how-to-enable-loyalty-management-with-crm",
      text: "Finding it difficult to manage loyalty, coupons, referral and marketing programs? We are providing you with best solutions. Start using crm and manage things easily. Go to Apps to view crm. Enable the app to start using it. Sign up to crm by clicking on click here or use the add integration ,How to enable loyalty management with crm",
    },
    {
      title: "Create managers and cashiers for access control",
      path: "posease-setup/create-managers-and-cashiers-for-access-control",
      text: "Differentiate different level of users to give them the role based access and improve the usage of Posease and also avoid fraudulent activities. Click on Setup -Users to add different types of users. Create Cashier/ Manager from Manager All Cashier Tab. Create All App Users from the All App user Tab.Create managers and cashiers for access control",
    },
    {
      title: "Get the downloadable version of Posease for desktop",
      text: "If you don't want to use Posease on a browser. You can download the installable version of Posease here. You can block the browser so that employees don't use it for other purposes. Download Posease desktop here - Windows 64, Windows 32, and Mac OSX.Get the downloadable version of Posease for desktop",
      path: "posease-setup/get-the-downloadable-version-of-Posease-for-desktop",
    },
  ],
  Billing: [
    {
      pageTitle: "Billing, taking orders",
      title: "Create KOT's to manage your kitchen orders",
      path: "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
      text: "Serving your customer orders at the right time is key to a happy customer. KOT's help you serialize your orders. To enable the KOT feature go to preferences. Refer the below link for more guidance. https://help.Posease.com/en/article/customize-your-pos-with-preferences-1dkw8ap/ Once the KOT feature is enabled the sell screen would have a order ticket button.",
    },
    {
      title: "Table management, take orders by table",
      path: "billing-taking-order/table-management-take-orders-by-table",
      text: "Manage your orders by table, also take care of takeaways and deliveries separately. Table management can be enabled for each register (shop) separately. To enable table management go to the respective register. In the selected register scroll down to table numbers. ",
    },
    {
      title: "Shift open/close, day end calculations",
      path: "billing-taking-order/shift-openclose-day-end-calculations",
      text: "Do you want reconcile accounts at a end of shift or end of the day, use the shift open/close feature. You can start a shift once you login in. Enter the starting cash balance and unlock to start shift.. Continue billing.",
    },
    {
      title: "Know all about selling",
      path: "billing-taking-order/know-all-about-selling",
      text: "In sell screen select products you want to add to current sale. To search for a product type any part of the product name to get shortlist of items. Adding product discount and bulk discount in the bill Click charge to select payment",
    },
    {
      title: "Print receipt before payment, for dine-in restaurants",
      path: "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
      text: "In dine-in restaurants the bill is first printed to receive the payment from customer. Then the payment type cash or credit card is chosen based on the payment. To enable the feature go to settings > preferences Add items to bill and click on charge. ",
    },
    {
      title: "Credit sales",
      path: "billing-taking-order/credit-sales",
      text: "Businesses always get payments at a later point of time. Make them as credit sales and track the receipts that are unpaid. Add products for the sale. Click charge for next screen. Select Credit Sales from drop down . Customer phone and name are mandatory fields for credit sale.  View Credit receipts Click receipts on side bar. Select the unpaid tab.",
    },
    {
      title: "Hold bill",
      path: "billing-taking-order/hold-bill",
      text: "If you want to hold bill as he customer is going to order more. You can use the hold option. To save a bill as draft select the Save & New option.  Enter the name of the draft eg. Table 2",
    },
    {
      title: "Quick billing, generate the bill in one click",
      path: "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
      text: "Sometimes your billing speed is critical to your business operation. You don't want to enter details like type of payment, customer details etc. You can go for the quick billing option. Go to settings - preferences, enable quick billing and save Now once you add items to a bill and click on charge, the bill will get generated immediately.",
    },
    {
      title: "Manage expenses in your store in Petty Cash",
      path: "billing-taking-order/manage-expenses-in-your-store",
      text: "Enter expenses and reconcile cash at the end of the day. To add an expense. Click on Add Expense. Enter the expense. The expense is added. To get the expense report. Click on Export to get the report.",
    },
    {
      title: "Apply Coupon codes in billing",
      path: "billing-taking-order/apply-coupon-codes-in-billing",
      text: "How to apply the coupon code during billing: Select the items to be sold in the Sell screen. Click coupon code button to enter the coupon code. Enter the coupon code and click Apply. Discount will added as Bulk Discount. ",
    },
    // {
    //   title: "Check For Waiter Active Or Not",
    //   path: "billing-taking-order/check-for-waiter-active-or-not",
    //   text: "Get a conformation if your waiter app if working fine from your desktop application. Go to Sell screen and check if the symbol is in green color. If the symbol is in red color, please check for the Server IP setup. Click Here to know how to set up the Server IP.",
    // },
    {
      title: "Table swap in web admin panel.",
      path: "billing-taking-order/table-swap-in-web-admin-panel",
      text: "If customer moves from one table to another, the orders can be swapped using our swap option. Select the table and take order. Click on swap and select a table. The table is now swapped.",
    },
    {
      title: "Manage Tables based on Sections",
      path: "billing-taking-order/manage-tables-based-on-sections",
      text: "Create Table sections to manage tables from multiple sections of your shop. Table Sections can be created for each register (shop) separately. To create table sections go to the respective register In the selected register scroll down to table numbers. ",
    },
    {
      title: "Split Payments: Receive payments from multiple payment modes",
      path: "billing-taking-order/split-payments-receive-payments-from-multiple-payment-modes",
      text: "Finding difficulties in billing the payment received from different payment modes ? You can use split payment option to enter the payments received from different sources into the same bill. Click on Sell, add the required products and click on charge to move to the payment screen. Click on Split payment to mark payments received from different payment modes. ",
    },
    {
      title: "Adding Delivery Details For Phone Customers.",
      path: "billing-taking-order/adding-delivery-details-for-phone-customers",
      text: "Add the details of the Phone Orders of the customer without any difficulties. Get the details from the customer and add them as and then when the orders are being placed. Select Take away from the sell screen. Select the product ordered by the customer and add their details by clicking on Add Customer.",
    },
    {
      title: "Split payment based on items wise",
      path: "billing-taking-order/split-payment-based-on-items-wise",
      text: "You can allow your customer to split / share the payments in two methods. Number of customers. Click here to split customer wise. Number of items. Please refer the following steps to split / share the payments. Customer wise split payments: Step 1: Go to 'Sell' tab to enter the billing items. Click on 'Charge' to split billing",
    },
    {
      title: "Split payments based upon customers",
      path: "billing-taking-order/split-payments-based-upon-customers",
      text: "You can allow your customer to split / share the payments in two methods. Number of customers Number of items. Please refer the following steps to split / share the payments. Customer wise split payments: Step 1: Go to 'Sell' tab to enter the billing items. Click on 'Charge' to split billing. Step 2: Click on Split button. ",
    },
  ],
  CustomerDetails: [
    {
      pageTitle: "Customer details",

      title: "View customer details and export",
      path: "customer-details/view-customer-details-and-export",
      text: "Knowing your customer is key to any businesses success. In Posease view the customer detail along with their order count, total value and the last time they shopped with you. While exporting customers the receiving email can be given where the .csv files is to be sent. ",
    },
    // {
    //   title: "Get customer details in Tablet/ Mobile app",
    //   text: "To add customer details in tablet/ Mobile app Select the ordered items and select Add customer. Enter the mobile number of the customer. If the customer has visited the shop already, it automatically fetches the other details. Select Add. ",
    // },

    {
      title: "Import customer data",
      path: "customer-details/import-customer-data",
      text: "Go ahead to import customer data in one shot Select customers from the menu. Then click on import.* Download the template for customer data import. Fill customer's details in the template. ",
    },
  ],
  // CustomerFeedback: [
  //   {
  //     pageTitle: "Customer feedback app",

  //     title: "View your customer feedback and their favorite products",
  //     text: "It is important to know what customers think about your service and make sure action is taken when there is dissatisfaction. See your customer feedback in the customer feedback app. To collect feedback the SMS app has to enabled, so that customers receive the digital receipts. Enable the customer feedback in the app store. View overall feedback for your store",
  //   },
  // ],
  Inventory: [
    {
      pageTitle: "Inventory app",
      title: "Create Ingredients (Raw material)",
      path:"inventory-app/create-ingredients-raw-material",
      text: "What is the most important factor that causes loss in the food industry? Make sure your raw material consumption is monitored to avoid it. Go to the inventory app in the app store. ",
    },
    {
      title: "Add inventory",
      path:"inventory-app/add-inventory",
      text: "Managing inventory is critical in avoiding wastage and making sure you have stocks to fulfill customer needs. Be informed. Go to apps in the menu  Add inventory to one register or multiple registers 3. Select inventory to ad",
    },
    {
      title: "Inventory operations",
      path:"inventory-app/inventory-operations",
      text: "Select the option depending on your requirement and update your inventory.",
    },
    {
      title: "Create recipe",
      path:"inventory-app/create-recipe",
      text: "Enter the recipe that make up your product. Go to manage items in the inventory. Then edit the product that you want to create the recipe for. Enter the recipe and the corresponding quantities.",
    },
    {
      title: "Inward a purchased product or raw material",
      path:"inventory-app/inward-a-purchased-product-or-raw-material",
      text: "To Inward a purchased product or raw material. Initially you have to add the purchased products in inventory to inward them. Click here to view how to add purchased product or raw materials. Firstly, choose the desired inventory from the inventory app to inward the purchased product. Choose inward option and enter the details of the product",
    },
    {
      title: "Manage Wastage in inventory",
      path:"inventory-app/manage-Wastage-in-inventory",
      text: "To avoid losses, it is a good practice to follow the wastage of your inventory. There by, You can monitor your left outs in your inventory by the following steps :- Select the inventory option from the apps menu. Select the desired inventory from which you want to look up on the wastage. Select the wa",
    },
    {
      title: "Adjustment of materials",
      path:"inventory-app/adjustment-of-materials",
      text: "In Posease we provide you the feature of adding and editing the quantity of the items through, 'ADJUSTMENT' option In this case we can maintain Our inventory avoiding and detecting pilferage. Select the Inventory option from the apps menu. Choose the desired inventory from which you want to adjust your items. Click on the adjustment option to modify the quantity of the items.",
    },
    // {
    //   title: "Consumption report, reduce pilferage",
    //   text: "Now get the rate of consumption of your inventory with the consumption report. To get the report go to the inventory and select the inventory. Click on export Select consumption report, select the date range and the email ID.",
    // },
    // {
    //   title: "Inventory bulk import",
    //   text: "Why update inventory one product at a time, save time by importing all of them in one go. Select inward in the inventory app.  Select import to import the .csv file. 3. You can download the .csv template needed to enter",
    // },
    // {
    //   title: "View stock history",
    //   text: "Always keep track of what is happening with your inventory. Make sure there are no fraudulent activities in the inventory operation. Click each history to view product details and the notes attached to it.",
    // },
    // {
    //   title: "Inventory costing",
    //   text: "In this article we will look at how to setup costing for items. Click on the inventory option from apps menu. Click on manage items option to setup raw material and products that you purchase from vendors. Click here to ch",
    // },
    // {
    //   title: "Inventory Stock Report",
    //   text: "Get the current stock in the inventory in a excel sheet using the stock report. To get the stock report, select the inventory Click on the Export option. Click on the Export option. Select stock report, and click on submit",
    // },
    // {
    //   title: "Inventory Indent",
    //   text: "Looking for a feature that helps you to request the inventory items between inventories? Use the indenting option to send and accept request among multiple inventories you owe. Click on inventory available under Apps -Inventory. Click on Indents to create indent request. Click here to add the new indent.",
    // },
    // {
    //   title: "Export inventory",
    //   text: "Select export and the report will be emailed to your registered email ID.",
    // },
    // {
      //   title: "Inventory operation- Transfer out",
      //   text: "We can also exchange the items and materials between the outlets. This is achieved by transfer out session available in the inventory app :- Choose the inventory option from the applications menu. Choose the desired inventory from which you want to transfer materials. Choose the transfer out option.",
      // },
    // {
    //   title: "Inventory Operations Re- order level",
    //   text: "it is important to get alerted about your stock level. This is aided by Posease by re-order level option by quantity in your inventory. Select the inventory option from the apps menu and select the desired inventory to mark re - order levels. Select Re-order level option to enter the limitations of the product the raw material.",
    // },
  ],
  // PoseaseFAQ: [
  //   {
  //     pageTitle: "Posease FAQ",

  //     title: "Get the downloadable version of Posease for desktop",
  //     text: "If you don't want to use Posease on a browser. You can download the installable version of Posease here. You can block the browser so that employees don't use it for other purposes. Download Posease desktop here - Windows 64, Windows 32, and Mac OSX.",
  //   },
  //   {
  //     title: "How does offline mode work?",
  //     text: "With Posease, your business can continue selling even when you are not connected to the internet or at times when your internet connectivity is patchy. When you loose connectivity, an info bar pops up on the Sell screen and lets you know know that you can continue billing and also shows you the number bills that are awaiting sync when the connectivity is back again. At the moment offline mode is supported only on Google Chrome browser and soon we would be adding support to other browsers as",
  //   },
  //   {
  //     title: "Posease Referral Program & Benefits",
  //     text: "Know about the Posease Referral Program. What is Posease Referral Program? Our customers have always loved & recommended us to fellow businesses. The Posease Referral Program is our attempt to recognise your referrals, and pass on benefits to both you and your referral. How does it work? Posease users will get a unique 'Referral Link', which they can use to share with their network. You will be able to find your referral link on the Dashboard page. Whenever you wish to reco",
  //   },
  //   {
  //     title: "Can we use key board shortcuts",
  //     text: "Quick billing in our utmost priority and hence we have added keyboard shortcuts. Now, hitting the F4 key will let you type and choose products, F7 lets you add customer mobile number and F2 confirms the bill.",
  //   },
  //   {
  //     title: "Forgot or Reset Password ?",
  //     text: "Forgot your password? Do not panic, we will help you to reset your password from our end. Click on Don't remember your password? link to reset your password. Get the requirements to reset the password in your mail id.",
  //   },
  //   {
  //     title: "Delete and remove custom fields eg:- payment types.",
  //     text: "The steps to delete or remove any custom field are:- Click on the setup menu and choose custom fields. Select the desired domain you want to remove. And choose the area by clicking on the check box. Click on the action drop-down list and choose the remove selected items option. ",
  //   },
  // ],
  // PoseaseSubscription: [
  //   {
  //     pageTitle: "Posease Subscription",

  //     title: "In app payment",
  //     text: " Wish to avail all the benefits of Posease? Move on with the paid version and use all the features and take your business a step ahead.Click on the Upgrade button to view the subscription models. Select the type of subscription you require either the monthly one or the yearly one according to your requirement.",
  //   },
  // ],
  printing: [
    {
      pageTitle: "Printing",

      title: "Add, save and remove printers (Desktop app)",
      path: "printing/add-save-and-remove-printers-desktop-app",
      text: "Add and save the printers in desktop app, so that you need not make the printer setup every time (Network and Silent Print). Print KOT directly in your kitchen using Network Printer. Select Printers from setup and click on Add Printer.",
    },
    // {
    //   title: "Print KOT in kitchen directly (Tablet/ Mobile app)",
    //   text: "Posease Mobile app (Android) allows you to print KOTs directly to a bluetooth or network printer kept in the kitchen which will eliminate manual work. To make the printer setup in mobile app refer the link below. KOT and receipt can be printed from same or separate printer. Select the printers and click on Save.",
    // },

    // {
    //   title: "Remote KOT printing in kitchen for restaurants",
    //   text: "For restaurants with a kitchen, it saves lot of time when the KOT is printed in the kitchen directly. To print KOT separately you have to use Posease Desktop not from a browser. Download Posease desktop here - Windows 64, Windows 32, and Mac OSX.",
    // },
    {
      title: "Avoid seeing print preview",
      path:"printing/avoid-seeing-print-preview",
      text: "Windows: Check that your receipt printer is set as your default printer. Create a Google Chrome shortcut on your desktop, rename it to like Posease to make unique. Right click on the new shortcut on your Desktop and select 'Properties' and click on 'Shortcut' TAB. At the end of the target field add: --kiosk-printing and click OK.  You are done !",
    },
    {
      title: "Select receipt size (printer type)",
      path: "printing/select-receipt-size-printer-type",
      text: "Choose your receipt size depending on printer Click on setup on left bottom of the screen. Select 'Registers'. Select register for which receipt size needs to be updated. Select the receipt print size",
    },
    {
      title: "Static IP address for printers",
      path:"printing/static-ip-address-for-printers",
      text: "Steps to set statics ip for your printers are as follows:- Click Start Menu Control Panel Network and Sharing Center or Network and Internet Network and Sharing Center. Click Change adapter settings. Right-click on Wi-Fi or Local Area Connection and Click Properties. Select Internet Protocol Version 4",
    },
    {
      title: "Static IP address for mac",
      path:"printing/static-ip-address-for-mac",
      text: "To set static IP address for Mac (iOS) follow the steps:- Go to settings and click on network option Choose your network connection by clicking on network name, then click on advanced option. Select TCP/IP, Click on drop down list of Configure ip4 and select manually option. ",
    },
  ],
  ReceiptSetup: [
    {
      pageTitle: "Receipt Setup",

      title: "Editing receipt header, footer & Disabling print",
      path: "receipt-setup/editing-receipt-header-footer-disabling-print",
      text: "Customize your receipts as per requirements. Add contact details, offers, information to customers etc. on the receipt. Click on setup on left bottom of the screen. Select 'Registers'. Select register for which bill needs to be updated. Update bill header and footer as required. Deselect 'Enable receipt printing' to disable printing.",
    },
    {
      title: "Cancel & Delete receipt",
      path: "receipt-setup/cancel-delete-receipt",

      text: "There is every chance a bill is incorrectly entered or cancelled. You can cancel the bill and Posease tracks the cancelled bills to make sure fraud is avoided. Click receipts on side bar.  Receipt details are displayed. Click on cancel to void the receipt. This will keep the receipt in the POS with cancelled status",
    },
    {
      title: "Enable strict receipt number",
      path: "receipt-setup/enable-strict-receipt-number",

      text: "If your government regulations say that the receipt number on the bill should be exactly the same as the one submitted in the filings. Also these receipt numbers should be in a sterilized format for the whole year. Then you should have strict receipt numbers enabled. The key thing is you should not bill on multiple systems for a single register in Posease. Click on setup on left bottom of the screen. Select 'preferences'. Now enable sequential local receipt numbers",
    },
  ],
  Reporting: [
    // {
    //   pageTitle: "Reporting",

    //   title: "How to get analytics app on mobile (only Android)",
    //   text: "To get analytics app on your android phone. Go to chrome browser and type the url 'analytics.Posease.com'. A popup would appear at the bottom. If the popup doesn't appear, once you login into the app. Select 'Add to Home screen' from the menu. ",
    // },
    {
      pageTitle: "Reporting",
      title: "Export reports",
      path: "reporting/export-reports",
      text: "Get the below reports and track your business. The reports are sent to your preferred email address. Sales Payment Daily sales and payment Product-wise sales To download the reports follow these steps. Click receipts on side bar. Click on Export and select the date range of report.  ",
    },
    {
      title: "Inventory Consumption Report",
      text: "The inventory consumption report gives the utilisation of the items over given a period of time. This helps you in estimating the future demand for product and raw material. The following steps helps you to understand the consumption report of an item(For Eg. Veg Noodles) Initial quantity of an item is the quantity of that item at the start of the period.",
    },
    // {
    //   title: "Analytics app - See your store performance",
    //   text: "You can see how your store is performing now with the analytics app. To enable the analytics app, go to the app store in Posease Select your store time zone and the choose the users who need access to use the Analytics app.",
    // },
    // {
    //   title: "Analytics app: How to view and compare data",
    //   text: "Once you have logged into the analytics app. You can browse through the different metrics. Now click on sales to view the sale metric in detail. To compare sales between two date ranges, click on date and enable compare.",
    // },
    {
      title: "KOT Report - View completed & cancelled KOTs (Avoid fraud)",
      path: "reporting/kot-report-view-completed-and-cancelled-kots-avoid-fraud",
      text: "It is important to assure the items in the KOT and the sales match. Also the cancelled KOT's have to be tracked to avoid fraud. Completed and cancelled Kitchen Order Tickets can be viewed for a particular receipt or for several receipts by exporting the Order Tickets Report. To access the cancelled kitchen order ticket of a particular receipt, Click Receipts select a receipt Click on the Order Ticket number to view the cancelled items in that kitchen.",
    },
    {
      title: "Shift open/close report",
      path: "reporting/shift-openclose-report",
      text: "You can get a report of all your shifts with the shift open/close report. Go to receipts tab and click on export. Select Shift open/close report type and export the report to your email ID.",
    },
    {
      title: "Metrics in Dashboard",
      path: "reporting/metrics-in-dashboard",
      text: "You can get a quick update on the status of the sales on a particular day or month. Select by register if you have multiple registers. The information available on the dashboard are Total sales Total bills New customers Top selling product",
    },
    // {
    //   title: "Download purchase report to get total cost",
    //   text: "To download the purchase report :- Click on the inventory option from the apps menu. Select the desired inventory from which you want to download reports. Click on the export option to download the report. Select consumption report, range and your e-mail details to download",
    // },
  ],
  // SellingWithTabletMobile: [
  //   {
  //     pageTitle: "Selling with tablet / mobile",

  //     title: "Sell on a Tablet/phone",
  //     text: "Before selling all required setup has to be completed in the web version of Posease. Once done login into the Posease app on your tablet/phone. Click items to add them, click on add customer to collect customer details.",
  //   },
  //   {
  //     title: "Cancel/ Delete receipts in Tablet/ Mobile app",
  //     text: "You can cancel and delete a receipt in the Tablet/ Mobile app Select Receipts and click on the receipt to be cancelled. Select Cancel. Enter the Refund Amount, Refund Payment Type , Cancellation notes and click on Cancel Receipt. ",
  //   },

  //   {
  //     title: "Table management, take orders by table in Tablet/ Mobile app",
  //     text: "Manage your orders by table. Select the table and take order. You can Hold a table if the orders are not completed.",
  //   },

  //   {
  //     title: "Add discounts in Tablet/ mobile app",
  //     text: "To Add bulk discount Add items to the receipt and click on bulk discount. Select the discount type and update. Bulk discount is added to the total charge. ",
  //   },
  // ],
  // SMSApp: [
  //   {
  //     pageTitle: "SMS APP",

  //     title: "SMS app, send digital receipts (Go paperless)",
  //     text: "Customers always loose their bills. Send SMS receipts to make sure they always have a copy of the bill for easy reference.Select App from sidebar. Enable the SMS app to start using it. Setup the SMS as below.",
  //   },
  //   {
  //     title: "Get customer feedback through SMS receipts",
  //     text: "Knowing what your customer liked and disliked is key to sustenance of the business. Customers have the option to give feedback on their digital receipts. Once the customer receives the SMS with a link to the bill. He can click on it to open it. The bills are available for 10 years. In the digital SMS receipt can give feedback about the service as well as select",
  //   },
  //   {
  //     title: "SMS credits",
  //     text: "Posease provides you a efficient way to handle and upgrade your CRM with SMS. This can be acknowledged by you in the SMS apps session:- Click on the APPS menu and click on view SMS app. Click on the usage option to view the following :- To view the past data of sent SMS. To view the available credits. !",
  //   },
  // ],
  // TabletMobileAppSetup: [
  //   {
  //     pageTitle: "Tablet / mobile app setups",

  //     title: "Getting started with tablet/phone App",
  //     text: "You can make sales from a tablet or mobile with ease. Download app(Posease) from iOS/Android store Launch app and you will see the login/signup page.  Click on the Login / Signup to Posease and you will be taken to a new browser page outside the app to login or signup. You can signup by using one of following two methods or login if you have already cre",
  //   },
  //   {
  //     title: "Sync products and setup in tablet/ mobile app",
  //     text: "Setup has to be synced in tablet/mobile after update in the web admin panel. Go to setup in the app. Click on sync to update setup and products.",
  //   },
  //   {
  //     title: "Shift open/close for Tablet/ Mobile app",
  //     text: "You can use the shift open/close to consolidating cash at the end of the day. It gives a report of cash shortage or excess cash. Open a shift Enter the owner/ cashier PIN to start a new shift. Enter the opening cash balance and Unlock. ",
  //   },
  //   {
  //     title: "Update setup from web in tablet/mobile",
  //     text: "Major setup like product creation, tax, etc. has to be done on the web version of Posease. Once the setup is complete it has to be synced in the mobile app. ",
  //   },
  //   {
  //     title: "Expense Management in Tablet/ Mobile App",
  //     text: "Enter expenses and reconcile cash at the end of the day. Expenses can be added in offline mode, it will be synced automatically when you get back the internet connectivity. To add an expense. Select the Expenses option. Click on Add Expense. Enter the expense. ",
  //   },
  //   {
  //     title: "Table management for Tablet/ mobile app",
  //     text: "Table management can be enabled for each register (shop) separately, this has to be done from the web admin panel first. To enable table management go to the respective register in web admin panel. In the selected register scroll down to table numbers. ",
  //   },
  // ],
  // TabletMobileWirelessPrinter: [
  //   {
  //     pageTitle: "Tablet / mobile wireless printing",

  //     title: "Printer setup (Wi-fi network printer) for tablet/phone app",
  //     text: "Before configuring the POS. The IP of the printer has to set and it has to in the series of the modem used.",
  //   },
  //   {
  //     title: "Print from a bluetooth printer from a android mobile/tablet",
  //     text: "Businesses like food trucks, qsr etc. require a mobile bluetooth printer for employees to move around. Use our android app to connect to a Bixolon bluetooth printer.. To setup bluetooth printing, follow the steps below. Click setup -Add printer Switch on the Bluetooth and then connect the printer.",
  //   },
  //   {
  //     title: "Printer Setup (Android Print) for Tablet / Mobile",
  //     text: "Add and save the printers in Mobile / Tablet app, so that you need not make the printer setup every time. Print KOT directly in your kitchen using Network Printer. ",
  //   },
  // ],
  // UrbanDocuments: [
  //   {
  //     pageTitle: "Urban Piper Document",

  //     title: "Price changes in Urban Piper",
  //     text: "Wish to add new items to online food order portal? Do it right away from the Posease itself. Avoid tedious steps to add new item into online menu. Make sure the product/item already exists in Posease. How to Add Product? Click on Item Setup to change item properties. Create the online category by clicking on On",
  //   },
  //   {
  //     title: "Adding new items in Urban Piper",
  //     text: "Wish to add new items to online food order portal? Do it right away from the Posease itself. Avoid tedious steps to add new item into online menu. Make sure the product/item already exists in Posease. How to Add Product? Wish to add new items to online food order portal? Do it right away from the Posease itself. Avoid tedious steps to add new item into online menu. Make sure the product/item already exists in Posease. How to Add Product? Click on Item Setup to change item properties. Create the online category by clicking on On Click on Item Setup to change item properties. Create the online category by clicking on On",
  //   },
  //   {
  //     title: "Item In/Out of Stock in Urban Piper",
  //     text: "Running out of stock and wish to stop the item immediately on online food order portals? Follow 3 simple steps and mark the required items as Out of stock. In your Posease application, Click on Apps -Urban Piper (View). Select the register to which you wish to make changes. Select the items that yo",
  //   },
  //   {
  //     title: "Enable or Disable the store in Urban Piper",
  //     text: "Taking off for a day or two? Disable your online orders and revoke them as and when required. On the Posease application, click on Apps -Urban Piper(View). Select the required register/ store location that has to be enabled or disabled -Actions -Enable Ordering / Disable Ordering. ",
  //   },
  //   {
  //     title: "How to sync store from Urban Piper Hub",
  //     text: "Push your menu from urban piper to the swiggy, zomato portals in 3 simple steps. Login into Urban Piper portal with your user name and password. Click on Hub -Platforms to view all your store/ locations. Under platform, select the respective platform and register in which the changes are to be m",
  //   },
  //   {
  //     title: "Enabling/Disabling old items in Urban Piper",
  //     text: "Wish to enable/disable the items on online food order portal? In 4 exact steps, disable the item for online food order portals alone. In Posease application, click on Apps -Urban Piper(View). Click on Item Setup to change item properties. Select the items you wish to enable/disable and click on Actio",
  //   },
  //   {
  //     title: "Manage Options in Urban Piper",
  //     text: "You can manage each and every individual options in swiggy and zomato portal. Following steps will help you doing it. Make sure the product/item options (varian/addon) already exists in Posease. Create variants for products/ Create add-ons for products Click on Item Setup to change item properties. ",
  //   },
  //   {
  //     title: "Settings in Urban Piper",
  //     text: "Customize your views in urban piper hub according to your convenience. Find the options to enhance the usage of Urban Piper Hub. In Posease application, click on Apps -Urban Piper(View). Click on settings to view the available setting. Enable the portals that you are live and update preferences accordin",
  //   },
  // ],
};
