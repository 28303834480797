import { Button, Layout, Menu } from "antd";
import "./NavBar.css";
import { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
// import { Card } from "antd";
// import { LeftOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const { Sider } = Layout;

const Navbar = () => {
  // let history = useNavigate();
  const location = useLocation();
// console.log("---------",location.state.key)
const pathArray = window.location.pathname.split('/')
const mainPath = pathArray[2]
const [path, setPath] = useState(`${mainPath? mainPath : "posease-subscription"}`);

useEffect(() => {
  // if (mainPath == "") {
  //   setPath("posease-subscription");
  // } else{
    setPath(mainPath);
  // }
}, [location]);
// console.log("mainpath---",mainPath)
  return (
    <div style={{ marginRight: "20px" }}>
    
      <h3 style={{marginBottom:"10px"}}>Categories</h3>
      <Sider width={200}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[path]}
          style={{
            height: "100%",
            borderRight: 0,
            fontSize: "13.5px",
            background: "#f3f6fb",
            border: "none",
          }}
        >
          {/* <Menu.Item key="posease-subscription" className="menu-item">
            <NavLink to="/posease-subscription">Posease Subscription</NavLink>
          </Menu.Item>
          <Menu.Item key="Urban-piper-Documents" className="menu-item">
            <NavLink to="/Urban-piper-Documents">Urban Piper Documents</NavLink>
          </Menu.Item> */}
          <Menu.Item key="posease-setup" className="menu-item">
            <NavLink to="/posease-setup">Posease setup</NavLink>
          </Menu.Item>
          <Menu.Item key="product-setup" className="menu-item">
            <NavLink to="/product-setup">Product setup</NavLink>
          </Menu.Item>
          <Menu.Item key="billing-taking-order" className="menu-item">
            <NavLink to="/billing-taking-order">Billing, taking order</NavLink>
          </Menu.Item>
          <Menu.Item key="receipt-setup" className="menu-item">
            <NavLink to="/receipt-setup">Receipt setup</NavLink>
          </Menu.Item>
          <Menu.Item key="printing" className="menu-item">
            <NavLink to="/printing">Printing</NavLink>
          </Menu.Item>
          <Menu.Item key="reporting" className="menu-item">
            <NavLink to="/reporting">Reporting</NavLink>
          </Menu.Item>
          <Menu.Item key="customer-details" className="menu-item">
            <NavLink to="/customer-details">Customer details</NavLink>
          </Menu.Item>
          <Menu.Item key="inventory-app" className="menu-item">
            <NavLink to="/inventory-app">Inventory App</NavLink>
          </Menu.Item>
          {/* <Menu.Item key="SMS-app" className="menu-item">
            <NavLink to="/SMS-app">SMS app</NavLink>
          </Menu.Item>
          <Menu.Item key="Customer-feedback-app" className="menu-item">
            <NavLink to="/Customer-feedback-app">Customer feedback app</NavLink>
          </Menu.Item>
          <Menu.Item key="posease-FAQ" className="menu-item">
            <NavLink to="/posease-FAQ">Posease FAQ</NavLink>
          </Menu.Item>
          <Menu.Item key="tablet-mobile-app-setup" className="menu-item">
            <NavLink to="/tablet-mobile-app-setup">
              Tablet / Mobile app setup
            </NavLink>
          </Menu.Item>
          <Menu.Item key="tablet-mobile-wireless-printer" className="menu-item">
            <NavLink to="/tablet-mobile-wireless-printer">
              Tablet/ mobile wireless printer
            </NavLink>
          </Menu.Item>
          <Menu.Item key="selling-with-tablet-mobile" className="menu-item">
            <NavLink to="/selling-with-tablet-mobile">
              Selling with tablet / mobile
            </NavLink>
          </Menu.Item> */}
        </Menu>
      </Sider>
    </div>
  );
};

export default Navbar;
