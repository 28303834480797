import { ArrowLeftOutlined } from "@ant-design/icons";
import {  Layout, Menu } from "antd";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { NavbarCSS } from "./UserGuideWithSSNavbarCSS";
const { Sider } = Layout;

const UseguideWithSSNavbar = (props) => {
//   console.log("m", props.prop.Navbar);
  const history = useNavigate();
  // const location = useLocation();
  // console.log("---------",location.state.key)
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[3];
  const [path, setPath] = useState(
    `${mainPath ? mainPath : "posease-subscription"}`
  );

  useEffect(() => {
    // if (mainPath == "") {
    //   setPath("");
    // } else {
      setPath(mainPath);
    // }
  }, [mainPath]);
  return (
    <div style={{ width: "260px" }}>
      <div
        style={{ marginLeft: "30px", marginTop: 35, cursor: "pointer" }}
        onClick={() => {
          history(`/${props.prop.pageLink}`);
        }}
      >
        <span style={{ marginRight: 10 }}>
          <ArrowLeftOutlined />
        </span>
        <span
          style={{
            padding: "10px 14px",
            fontWeight: 500,
            background: "#bd025d",
            color: "white",
            marginTop: "",
            borderRadius: 2,
          }}
        >
          {props.prop.pagetitle}
        </span>
      </div>
      <h3 style={{ marginBottom: 5, marginTop: "40px", marginLeft: "30px" }}>
        Articles
      </h3>
      <Sider width={200}>
        <NavbarCSS>
          <Menu
            mode="inline"
            defaultSelectedKeys={[path]}
            style={{
              height: "100%",
              borderRight: 0,
              fontSize: "13.5px",
              background: "#f3f6fb",
              border: "none",
              // paddingLeft:
            }}
          >
            {props.prop.Navbar.map((Navbar, index) => (
              <Menu.Item key={`${Navbar.menuKey}`} className="menu-item">
                <NavLink to={`/${Navbar.Navlink}`}>
                  {Navbar.subPageTitle} 
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        </NavbarCSS>
      </Sider>
    </div>
  );
};

export default UseguideWithSSNavbar;
