// import billstopay from "./img/bills_to_pay.png";
// import chatFAQ from "./img/chatFAQ.png";
import inventory from "./img/inventory.png";
// import mobile_phone from "./img/mobile_tablet.png";
// import mobileandtablet from "./img/mobileandtablet.png";
// import onlineorder from "./img/online-order.png";
// import ratingimg from "./img/star_rating.png";
// import textimg from "./img/text_mobile.png";
// import wirlessimg from "./img/wirless_p.png";
// import { NavLink } from "react-router-dom";
import billingimg from "./img/billing_invoice.png";
import peopleimg from "./img/people.png";
import printerimg from "./img/printer.png";
import reportimg from "./img/report.png";
import setupwheel from "./img/setup-wheel.png";
import cartimg from "./img/shop-cart.png";
import storetag from "./img/storetag.png";
export const FrequentlyReadArticles = [
  {
    p: "Get the downloadable version of Posease for desktop",
    path:"posease-setup/get-the-downloadable-version-of-Posease-for-desktop"
  },
  // {
  //   p: "How to get analytics app on mobile (only Android)",
  // },
  {
    p: "Add, Edit & Delete products",
    path:"product-setup/add-edit-delete-product",
  },
  {
    p: "How to enable and use Waiter/Captain App for table orders",
    path:"posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders"
  },
  {
    p: "Customize your POS with preferences",
    path:"posease-setup/customize-your-pos-with-preferences"
  },
  {
    p: "Create Ingredients (Raw material)",
    path:"inventory-app/create-ingredients-raw-material"
  },
];
export const allCategories = [
  {
    title: "Posease Setup",
    text: "Learn how to setup Posease",
    logo: setupwheel,
    path: "posease-setup",
  },
  {
    title: "Product Setup",
    text: "Learn to setup product and product categories",
    logo: storetag,

    path: "product-setup",
  },
  {
    title: "Billing, taking orders",
    text: "Learn how to make sales in Posease",
    logo: cartimg,

    path: "billing-taking-order",
  },
  {
    title: "Receipt Setup",
    text: "Change receipt details like header footer, cancel and deleted receipts",
    logo: billingimg,

    path: "receipt-setup",
  },
  {
    title: "Printing",
    text: "Select printer type, avoid print preview",
    logo: printerimg,

    path: "printing",
  },
  {
    title: "Reporting",
    text: "See useful insighs and download reports for analysis",
    logo: reportimg,

    path: "reporting",
  },
  {
    title: "Customer details",
    text: "View customer details and export them",
    logo: peopleimg,

    path: "customer-details",
  },
  {
    title: "Inventory app",
    text: "How maintain stock, do transfer, create reorder levels, maintain recipes",
    logo: inventory,

    path: "inventory-app",
  },
];
