// CreateVariant
import addVariant1 from "./image/addVariant1.png";
import addVariant2 from "./image/addVariant2.png";
import addVariant3 from "./image/addVariant3.png";
import addVariant4 from "./image/addVariant4.png";
import addVariant5 from "./image/addVariant5.png";
import addVariant6 from "./image/addVariant6.png";
import addVariant7 from "./image/addVariant7.png";
import addVariant8 from "./image/addVariant8.png";
import addVariant9 from "./image/addVariant9.png";
// AddEditDeleteProduct
import addProduct1 from "./image/addProduct1.png";
import addProduct2 from "./image/addProduct2.png";
import addProduct3 from "./image/addProduct3.png";
// AddProductCat
import AddProductCat1 from "./image/AddProductCat1.png";
// ImportExportProduct
import ImportExportProduct1 from "./image/ImportExportProduct1.png";
import ImportExportProduct2 from "./image/ImportExportProduct2.png";
import ImportExportProduct3 from "./image/ImportExportProduct3.png";
import ImportExportProduct4 from "./image/ImportExportProduct4.png";
import ImportExportProduct5 from "./image/ImportExportProduct5.png";
import ImportExportProduct6 from "./image/ImportExportProduct6.png";
import ImportExportProduct7 from "./image/ImportExportProduct7.png";
// ManagePricing
import manage1 from "./image/manage1.png";
import manage2 from "./image/manage2.png";
import manage3 from "./image/manage3.png";
import manage4 from "./image/manage4.png";
// CreateAddOns
import addon1 from "./image/addon1.png";
import addon2 from "./image/addon2.png";
import addon3 from "./image/addon3.png";
import addon4 from "./image/addon4.png";
import addon5 from "./image/addon5.png";
import addon6 from "./image/addon6.png";
import addon7 from "./image/addon7.png";
import addon8 from "./image/addon8.png";
// LimitProductToShop
import LimitProductToShop1 from "./image/LimitProductToShop1.png";
import LimitProductToShop2 from "./image/LimitProductToShop2.png";
import LimitProductToShop3 from "./image/LimitProductToShop3.png";
// AddComboProduct
import AddComboProduct1 from "./image/AddComboProduct1.png";
import AddComboProduct2 from "./image/AddComboProduct2.png";
import AddComboProduct3 from "./image/AddComboProduct3.png";
import AddComboProduct4 from "./image/AddComboProduct4.png";
import AddComboProduct5 from "./image/AddComboProduct5.png";
import AddComboProduct6 from "./image/AddComboProduct6.png";
import AddComboProduct7 from "./image/AddComboProduct7.png";
// Receipt Setup
// editReceipt
import editReceipt from "./image/editReceipt.png";
// enable receipt
import enableReceiptNo1 from "./image/enableReceiptNo1.png";
import enableReceiptNo2 from "./image/enableReceiptNo2.png";
import enableReceiptNo3 from "./image/enableReceiptNo3.png";
// Billind and taking order
// manageKitchen
import manageKitchen1 from "./image/manageKitchen1.png";
import manageKitchen2 from "./image/manageKitchen2.png";
import manageKitchen3 from "./image/manageKitchen3.png";
// cancelDeleteReceipt
import cancelDeleteReceipt1 from "./image/cancelDeleteReceipt1.png";
import cancelDeleteReceipt2 from "./image/cancelDeleteReceipt2.png";
import cancelDeleteReceipt3 from "./image/cancelDeleteReceipt3.png";
import cancelDeleteReceipt4 from "./image/cancelDeleteReceipt4.png";
import cancelDeleteReceipt5 from "./image/cancelDeleteReceipt5.png";
import cancelDeleteReceipt6 from "./image/cancelDeleteReceipt6.png";

// tableManagement
import tableManagement1 from "./image/tableManagement1.png";
import tableManagement2 from "./image/tableManagement2.png";
import tableManagement3 from "./image/tableManagement3.png";
import tableManagement4 from "./image/tableManagement4.png";
import tableManagement5 from "./image/tableManagement5.png";
import tableManagement6 from "./image/tableManagement6.png";
import tableManagement7 from "./image/tableManagement7.png";
import tableManagement8 from "./image/tableManagement8.png";
import tableManagement9 from "./image/tableManagement9.png";
import tableManagement10 from "./image/tableManagement10.png";
// shiftOpenClose1
import shiftOpenClose1 from "./image/shiftOpenClose1.png";
import shiftOpenClose2 from "./image/shiftOpenClose2.png";
import shiftOpenClose3 from "./image/shiftOpenClose3.png";
import shiftOpenClose4 from "./image/shiftOpenClose4.png";
import shiftOpenClose5 from "./image/shiftOpenClose5.png";
import shiftOpenClose6 from "./image/shiftOpenClose7.png";
// printReceipt
import printReceipt1 from "./image/printReceipt1.png";
import printReceipt2 from "./image/printReceipt2.png";
import printReceipt3 from "./image/printReceipt3.png";
import printReceipt4 from "./image/printReceipt4.png";
// aboutSelling
import aboutSelling1 from "./image/aboutSelling1.png";
import aboutSelling2 from "./image/aboutSelling2.png";
import aboutSelling3 from "./image/aboutSelling3.png";
import aboutSelling4 from "./image/aboutSelling4.png";
// creditSales
import creditSales1 from "./image/creditSales1.png";
import creditSales2 from "./image/creditSales2.png";
import creditSales3 from "./image/creditSales3.png";
// holdBill
import holdBill1 from "./image/holdBill1.png";
import holdBill2 from "./image/holdBill2.png";
import holdBill3 from "./image/holdBill3.png";
// quickBilling
import quickBilling1 from "./image/quickBilling1.png";
import quickBilling2 from "./image/quickBilling2.png";
// tableSwap
import tableSwap1 from "./image/tableSwap1.png";
import tableSwap2 from "./image/tableSwap2.png";
import tableSwap3 from "./image/tableSwap3.png";
import tableSwap4 from "./image/tableSwap4.png";
// SectionTable
import SectionTable1 from "./image/SectionTable1.png";
import SectionTable2 from "./image/SectionTable2.png";
import SectionTable3 from "./image/SectionTable3.png";
// splitBill
import splitBill1 from "./image/splitBill1.png";
import splitBill2 from "./image/splitBill2.png";
import splitBill3 from "./image/splitBill3.png";
import splitBill4 from "./image/splitBill4.png";
import splitBill5 from "./image/splitBill5.png";
// addingDelivery
import addingDelivery1 from "./image/addingDelivery1.png";
import addingDelivery2 from "./image/addingDelivery2.png";
import addingDelivery3 from "./image/addingDelivery3.png";
import addingDelivery4 from "./image/addingDelivery4.png";
import addingDelivery5 from "./image/addingDelivery5.png";
// splitBillbasedOnItem
import splitBillbasedOnItem1 from "./image/splitBillbasedOnItem1.png";
import splitBillbasedOnItem2 from "./image/splitBillbasedOnItem2.png";
import splitBillbasedOnItem3 from "./image/splitBillbasedOnItem3.png";
import splitBillbasedOnItem4 from "./image/splitBillbasedOnItem4.png";
import splitBillbasedOnItem5 from "./image/splitBillbasedOnItem5.png";
import splitBillbasedOnItem6 from "./image/splitBillbasedOnItem6.png";
// splitBillBasedOnCustomers
import splitBillBasedOnCustomers1 from "./image/splitBillBasedOnCustomers1.png";
import splitBillBasedOnCustomers2 from "./image/splitBillBasedOnCustomers2.png";
import splitBillBasedOnCustomers3 from "./image/splitBillBasedOnCustomers3.png";
import splitBillBasedOnCustomers4 from "./image/splitBillBasedOnCustomers4.png";
// pettycash
import PettyCash1 from "./image/PettyCash1.png";
import PettyCash2 from "./image/PettyCash2.png";
import PettyCash3 from "./image/PettyCash3.png";
import PettyCash4 from "./image/PettyCash4.png";

// poseaseSetup
// waiterCustomer
import WaiterCustomer1 from "./image/WaiterCustomer1.png";
import WaiterCustomer2 from "./image/WaiterCustomer2.png";
import WaiterCustomer3 from "./image/WaiterCustomer3.png";
import WaiterCustomer4 from "./image/WaiterCustomer4.png";
import WaiterCustomer5 from "./image/WaiterCustomer5.png";
import WaiterCustomer6 from "./image/WaiterCustomer7.png";

// CustomizePreferences
import CustomizePreferences1 from "./image/CustomizePreferences1.png";
import CustomizePreferences2 from "./image/CustomizePreferences2.png";
import CustomizePreferences3 from "./image/CustomizePreferences3.png";
import CustomizePreferences4 from "./image/CustomizePreferences4.png";
import CustomizePreferences5 from "./image/CustomizePreferences5.png";
// EmpAccessContro
import EmpAccessControl1 from "./image/EmpAccessControl1.png";
import EmpAccessControl2 from "./image/EmpAccessControl2.png";
import EmpAccessControl3 from "./image/EmpAccessControl3.png";
import EmpAccessControl4 from "./image/EmpAccessControl4.png";
import EmpAccessControl5 from "./image/EmpAccessControl5.png";
import EmpAccessControl6 from "./image/EmpAccessControl6.png";
// singleMultiTax
import singleMultiTax1 from "./image/singleMultiTax1.png";
import singleMultiTax2 from "./image/singleMultiTax2.png";
import singleMultiTax3 from "./image/singleMultiTax3.png";
import singleMultiTax4 from "./image/singleMultiTax4.png";
import singleMultiTax5 from "./image/singleMultiTax5.png";
// addMultiReg
import addMultiReg1 from "./image/addMultiReg1.png";
// additionalCharge
import additionalCharge1 from "./image/additionalCharge1.png";
import additionalCharge2 from "./image/additionalCharge2.png";
import additionalCharge3 from "./image/additionalCharge3.png";
import additionalCharge4 from "./image/additionalCharge4.png";
import additionalCharge5 from "./image/additionalCharge5.png";
// ownerPin
import ownerPin1 from "./image/ownerPin1.png";
// gettingStarted
import gettingStarted1 from "./image/gettingStarted1.png";
import gettingStarted2 from "./image/gettingStarted2.png";
import gettingStarted3 from "./image/gettingStarted3.png";
// customPaymentType1
import customPaymentType1 from "./image/customPaymentType1.png";
import customPaymentType2 from "./image/customPaymentType2.png";
import customPaymentType3 from "./image/customPaymentType3.png";
// EmployeeAccess
import EmployeeAccess1 from "./image/EmployeeAccess1.png";
// import EmployeeAccess2 from "./image/EmployeeAccess2.png";
// ADDTAGS
import AddTags1 from "./image/AddTags1.png";
import AddTags2 from "./image/AddTags2.png";
import AddTags3 from "./image/AddTags3.png";
import AddTags4 from "./image/AddTags4.png";
import AddTags5 from "./image/AddTags5.png";
// CreateCustomes
import CreateCustomes1 from "./image/CreateCustomes1.png";
import CreateCustomes2 from "./image/CreateCustomes2.png";
import CreateCustomes3 from "./image/CreateCustomes3.png";
import CreateCustomes4 from "./image/CreateCustomes4.png";
// KitchenOrderTicket
import KitchenOrderTicket1 from "./image/KitchenOrderTicket1.png";
import KitchenOrderTicket2 from "./image/KitchenOrderTicket2.png";
import KitchenOrderTicket3 from "./image/KitchenOrderTicket3.png";
import KitchenOrderTicket4 from "./image/KitchenOrderTicket4.png";
import KitchenOrderTicket5 from "./image/KitchenOrderTicket5.png";
import KitchenOrderTicket6 from "./image/KitchenOrderTicket6.png";
// CreateManagerCashiers
import CreateManagerCashiers1 from "./image/CreateManagerCashiers1.png";
import CreateManagerCashiers2 from "./image/CreateManagerCashiers2.png";
import CreateManagerCashiers3 from "./image/CreateManagerCashiers3.png";
import CreateManagerCashiers4 from "./image/CreateManagerCashiers4.png";
// DownLoadPosease
import DownLoadPosease1 from "./image/DownLoadPosease1.png";
// import DownLoadPosease2 from "./image/DownLoadPosease2.png";
// import DownLoadPosease3 from "./image/DownLoadPosease3.png";
// import DownLoadPosease4 from "./image/DownLoadPosease4.png";
// LoyaltyCrm
import LoyaltyCrm1 from "./image/LoyaltyCrm1.png";
import LoyaltyCrm2 from "./image/LoyaltyCrm2.png";
import LoyaltyCrm3 from "./image/LoyaltyCrm3.png";
import LoyaltyCrm4 from "./image/LoyaltyCrm4.png";
import LoyaltyCrm5 from "./image/LoyaltyCrm5.png";
import LoyaltyCrm6 from "./image/LoyaltyCrm6.png";
import LoyaltyCrm7 from "./image/LoyaltyCrm7.png";
import LoyaltyCrm8 from "./image/LoyaltyCrm8.png";
import LoyaltyCrm9 from "./image/LoyaltyCrm9.png";
import LoyaltyCrm10 from "./image/LoyaltyCrm10.png";
import LoyaltyCrm11 from "./image/LoyaltyCrm11.png";
import LoyaltyCrm12 from "./image/LoyaltyCrm12.png";

// printing
// addSavePrinter
import addSavePrinter1 from "./image/addSavePrinter1.png";
import addSavePrinter2 from "./image/addSavePrinter2.png";
import addSavePrinter3 from "./image/addSavePrinter3.png";
import addSavePrinter4 from "./image/addSavePrinter4.png";
import addSavePrinter5 from "./image/addSavePrinter5.png";
import addSavePrinter6 from "./image/addSavePrinter6.png";
import addSavePrinter7 from "./image/addSavePrinter7.png";
import addSavePrinter8 from "./image/addSavePrinter8.png";
// SelectReceiptSize
import SelectReceiptSize from "./image/SelectReceiptSize.png";
// IpAddressPri
import IpAddressPri1 from "./image/IpAddressPri1.png";
import IpAddressPri2 from "./image/IpAddressPri2.png";
import IpAddressPri3 from "./image/IpAddressPri3.png";
import IpAddressPri4 from "./image/IpAddressPri4.png";
import IpAddressPri5 from "./image/IpAddressPri5.png";
// IpAddressMac
import IpAddressMac1 from "./image/IpAddressMac1.png";
import IpAddressMac2 from "./image/IpAddressMac2.png";
import IpAddressMac3 from "./image/IpAddressMac3.png";
import IpAddressMac4 from "./image/IpAddressMac4.png";
import IpAddressMac5 from "./image/IpAddressMac5.png";
import IpAddressMac6 from "./image/IpAddressMac6.png";
// avoidPreview
import avoidPreview1 from "./image/avoidPreview1.png";

// reporting
// MetricsDashboard
import MetricsDashboard1 from "./image/MetricsDashboard1.png";
// exportReport
import exportReport1 from "./image/exportReport1.png";
import exportReport2 from "./image/exportReport2.png";
// ShiftOpenCloseReport
import ShiftOpenCloseReport1 from "./image/ShiftOpenCloseReport1.png";
import ShiftOpenCloseReport2 from "./image/ShiftOpenCloseReport2.png";
// ViewCompletedCancelledReport
import ViewCompletedCancelledReport1 from "./image/ViewCompletedCancelledReport1.png";
import ViewCompletedCancelledReport2 from "./image/ViewCompletedCancelledReport2.png";
import ViewCompletedCancelledReport3 from "./image/ViewCompletedCancelledReport3.png";
import ViewCompletedCancelledReport4 from "./image/ViewCompletedCancelledReport4.png";
import ViewCompletedCancelledReport5 from "./image/ViewCompletedCancelledReport5.png";
import ViewCompletedCancelledReport6 from "./image/ViewCompletedCancelledReport6.png";

// customerDetail
// exportCustomerDetail
import exportCustomerDetail1 from "./image/exportCustomerDetail1.png";
import exportCustomerDetail2 from "./image/exportCustomerDetail2.png";
// importCustomerDetail
import importCustomerDetail1 from "./image/importCustomerDetail1.png";
import importCustomerDetail2 from "./image/importCustomerDetail2.png";
// Inventory
// AdjustmentMaterials
import AdjustmentMaterials1 from "./image/AdjustmentMaterials1.png";
import AdjustmentMaterials2 from "./image/AdjustmentMaterials2.png";
import AdjustmentMaterials3 from "./image/AdjustmentMaterials3.png";
import AdjustmentMaterials4 from "./image/AdjustmentMaterials4.png";
// ManageWastage
import ManageWastage1 from "./image/ManageWastage1.png";
import ManageWastage2 from "./image/ManageWastage2.png";
import ManageWastage3 from "./image/ManageWastage3.png";
import ManageWastage4 from "./image/ManageWastage4.png";
// Inward
// import Inward1 from "./image/Inward1.png";
import Inward2 from "./image/Inward2.png";
import Inward3 from "./image/Inward3.png";
import Inward4 from "./image/Inward4.png";
// CreateRecipe
import CreateRecipe1 from "./image/CreateRecipe1.png";
import CreateRecipe2 from "./image/CreateRecipe2.png";
// InventoryOperations
import InventoryOperations from "./image/InventoryOperations.png";
// CreateIngredients
import CreateIngredients1 from "./image/CreateIngredients1.png";
import CreateIngredients2 from "./image/CreateIngredients2.png";
import CreateIngredients3 from "./image/CreateIngredients3.png";
import CreateIngredients4 from "./image/CreateIngredients4.png";
// AddInventory
import AddInventory1 from "./image/AddInventory1.png";
import AddInventory2 from "./image/AddInventory2.png";
import AddInventory3 from "./image/AddInventory3.png";
import AddInventory4 from "./image/AddInventory4.png";
import AddInventory5 from "./image/AddInventory5.png";


export const guide = {
  productSetup: {
    Navbar: [
      {
        menuKey: "add-edit-delete-product",
        Navlink: "product-setup/add-edit-delete-product",
        subPageTitle: "Add, Edit & Delete products",
      },
      {
        menuKey: "create-variants",
        Navlink: "product-setup/create-variants",
        subPageTitle: "Create variants for products",
      },
      {
        menuKey: "bulk-import-export-products",
        Navlink: "product-setup/bulk-import-export-products",
        subPageTitle: "Bulk import & export of products",
      },
      {
        menuKey: "add-product-categories",
        Navlink: "product-setup/add-product-categories",
        subPageTitle: "Add product categories",
      },
      {
        menuKey: "create-add-ons-for-products",
        Navlink: "product-setup/create-add-ons-for-products",
        subPageTitle: "Create add-ons for products",
      },
      {
        menuKey: "manage-multiple-pricing-for-products-using-price-book",
        Navlink:
          "product-setup/manage-multiple-pricing-for-products-using-price-book",
        subPageTitle: "Manage Multiple Pricing for...",
      },
      {
        menuKey: "limit-a-product-to-a-particular-register-shop",
        Navlink: "product-setup/limit-a-product-to-a-particular-register-shop",
        subPageTitle: "Limit a product to a particular...",
      },
      {
        menuKey: "add-Combo-products",
        Navlink: "product-setup/add-Combo-products",
        subPageTitle: "Add Combo Products",
      },
    ],
    CreateVariant: {
      pagetitle: "Product Setup",
      pageLink: "product-setup",
      subPageTitle: "Create variants for products",
      content: [
        {
          para: "if you have multiple variant of products, create variants and manage selling with ease.",
        },
        {
          para: "To create variants go to Manage Product > Options. Click on add variant.  ",
        },
        {
          img: addVariant1,
        },
        {
          para: "Enter the variants to be created, enter the price of each variant.For example you sell a small burger for 100 and regular burger at 140. Enter the whole price here,",
        },
        {
          img: addVariant2,
        },
        {
          para: "Create variant group for each product",
        },
        {
          img: addVariant3,
        },
        {
          img: addVariant4,
        },
        {
          para: "Select variants in that group.",
        },
        {
          img: addVariant5,
        },
        {
          para: "Now go to the product to which the variant has to be added. Make the product price zero and select one more variant groups.",
        },
        {
          img: addVariant6,
        },
        {
          img: addVariant7,
        },
        {
          para: "Now during sell you will see the product with its variants.",
        },
        {
          img: addVariant8,
        },
        {
          img: addVariant9,
        },
      ],
      Navbar: [
        {
          menuKey: "add-edit-delete-product",
          Navlink: "product-setup/add-edit-delete-product",
          subPageTitle: "Add, Edit & Delete products",
        },
        {
          menuKey: "create-variants",
          Navlink: "product-setup/create-variants",
          subPageTitle: "Create variants for products",
        },
        {
          menuKey: "bulk-import-export-products",
          Navlink: "product-setup/bulk-import-export-products",
          subPageTitle: "Bulk import & export of products",
        },
        {
          menuKey: "add-product-categories",
          Navlink: "product-setup/add-product-categories",
          subPageTitle: "Add product categories",
        },
        {
          menuKey: "create-add-ons-for-products",
          Navlink: "product-setup/create-add-ons-for-products",
          subPageTitle: "Create add-ons for products",
        },
        {
          menuKey: "manage-multiple-pricing-for-products-using-price-book",
          Navlink:
            "product-setup/manage-multiple-pricing-for-products-using-price-book",
          subPageTitle: "Manage Multiple Pricing for...",
        },
        {
          menuKey: "limit-a-product-to-a-particular-register-shop",
          Navlink:
            "product-setup/limit-a-product-to-a-particular-register-shop",
          subPageTitle: "Limit a product to a particular...",
        },
        {
          menuKey: "add-Combo-products",
          Navlink: "product-setup/add-Combo-products",
          subPageTitle: "Add Combo Products",
        },
      ],
    },
    AddComboProduct: {
      pagetitle: "Product Setup",
      pageLink: "product-setup",
      subPageTitle: "Add Combo Products",
      content: [
        {
          para: "Looking forward to create combos? Create combos to enhance the visibility of products to improve profit.",
        },
        {
          para: "1. Click on Setup > Product Options > Item Groups to create create item groups for creating combo product.",
        },
        {
          img: AddComboProduct1,
        },
        {
          para: "2. Create Item group by providing the name and select the products under the group.",
        },
        {
          img: AddComboProduct2,
        },
        {
          para: "3. Now assign the item to product to create combos.",
        },
        {
          img: AddComboProduct3,
        },
        {
          para: "4. Select the Combo option to shift the product to regular to combo product from product options. And select the item groups as required.",
        },
        {
          img: AddComboProduct4,
        },
        {
          img: AddComboProduct5,
        },
        {
          para: "5. Select one item from each item group and make it a combo.",
        },
        {
          img: AddComboProduct6,
        },
        {
          img: AddComboProduct7,
        },
      ],
      Navbar: [
        {
          menuKey: "add-edit-delete-product",
          Navlink: "product-setup/add-edit-delete-product",
          subPageTitle: "Add, Edit & Delete products",
        },
        {
          menuKey: "create-variants",
          Navlink: "product-setup/create-variants",
          subPageTitle: "Create variants for products",
        },
        {
          menuKey: "bulk-import-export-products",
          Navlink: "product-setup/bulk-import-export-products",
          subPageTitle: "Bulk import & export of products",
        },
        {
          menuKey: "add-product-categories",
          Navlink: "product-setup/add-product-categories",
          subPageTitle: "Add product categories",
        },
        {
          menuKey: "create-add-ons-for-products",
          Navlink: "product-setup/create-add-ons-for-products",
          subPageTitle: "Create add-ons for products",
        },
        {
          menuKey: "manage-multiple-pricing-for-products-using-price-book",
          Navlink:
            "product-setup/manage-multiple-pricing-for-products-using-price-book",
          subPageTitle: "Manage Multiple Pricing for...",
        },
        {
          menuKey: "limit-a-product-to-a-particular-register-shop",
          Navlink:
            "product-setup/limit-a-product-to-a-particular-register-shop",
          subPageTitle: "Limit a product to a particular...",
        },
        {
          menuKey: "add-Combo-products",
          Navlink: "product-setup/add-Combo-products",
          subPageTitle: "Add Combo Products",
        },
      ],
    },
    AddEditDeleteProduct: {
      pagetitle: "Product Setup",
      pageLink: "product-setup",
      subPageTitle: "Add, Edit & Delete products",
      content: [
        {
          para: "Add new products, edit or delete old products in shop.",
        },
        {
          para: "1. Click on products in the menu And select Add Products",
        },
        {
          img: addProduct1,
        },
        {
          para: "2. Enter all the products and save.",
        },
        {
          img: addProduct2,
        },
        {
          para: "3. To delete product",
          subpara: [
            {
              subpara: "Select products to be deleted",
            },
            {
              subpara: "Click on Actions",
            },
          ],
        },
        {
          img: addProduct3,
        },
      ],
      Navbar: [
        {
          menuKey: "add-edit-delete-product",
          Navlink: "product-setup/add-edit-delete-product",
          subPageTitle: "Add, Edit & Delete products",
        },
        {
          menuKey: "create-variants",
          Navlink: "product-setup/create-variants",
          subPageTitle: "Create variants for products",
        },
        {
          menuKey: "bulk-import-export-products",
          Navlink: "product-setup/bulk-import-export-products",
          subPageTitle: "Bulk import & export of products",
        },
        {
          menuKey: "add-product-categories",
          Navlink: "product-setup/add-product-categories",
          subPageTitle: "Add product categories",
        },
        {
          menuKey: "create-add-ons-for-products",
          Navlink: "product-setup/create-add-ons-for-products",
          subPageTitle: "Create add-ons for products",
        },
        {
          menuKey: "manage-multiple-pricing-for-products-using-price-book",
          Navlink:
            "product-setup/manage-multiple-pricing-for-products-using-price-book",
          subPageTitle: "Manage Multiple Pricing for...",
        },
        {
          menuKey: "limit-a-product-to-a-particular-register-shop",
          Navlink:
            "product-setup/limit-a-product-to-a-particular-register-shop",
          subPageTitle: "Limit a product to a particular...",
        },
        {
          menuKey: "add-Combo-products",
          Navlink: "product-setup/add-Combo-products",
          subPageTitle: "Add Combo Products",
        },
      ],
    },
    CreateAddOns: {
      pagetitle: "Product Setup",
      pageLink: "product-setup",
      subPageTitle: "Create add-ons for products",
      content: [
        {
          para: "if you have multiple add-ons of products, create add-ons and manage selling with ease.",
        },
        {
          para: "To create add-ons go to setup > product options. Click on addons.",
        },
        {
          img: addon1,
        },
        {
          para: "Enter the add-ons to be created, enter the price of each add-on.",
        },
        {
          img: addon2,
        },
        {
          para: "Create add-on group for each product",
        },
        {
          img: addon3,
        },
        {
          para: "Select add-ons in that group.",
        },
        {
          img: addon4,
        },
        {
          para: "Now go to the product to which the add-on has to be added. Select one or more add-on groups.",
        },
        {
          img: addon5,
        },
        {
          img: addon6,
        },
        {
          para: "Now during sell you will see the product with its add-ons.",
        },
        {
          img: addon7,
        },
        {
          img: addon8,
        },
      ],
      Navbar: [
        {
          menuKey: "add-edit-delete-product",
          Navlink: "product-setup/add-edit-delete-product",
          subPageTitle: "Add, Edit & Delete products",
        },
        {
          menuKey: "create-variants",
          Navlink: "product-setup/create-variants",
          subPageTitle: "Create variants for products",
        },
        {
          menuKey: "bulk-import-export-products",
          Navlink: "product-setup/bulk-import-export-products",
          subPageTitle: "Bulk import & export of products",
        },
        {
          menuKey: "add-product-categories",
          Navlink: "product-setup/add-product-categories",
          subPageTitle: "Add product categories",
        },
        {
          menuKey: "create-add-ons-for-products",
          Navlink: "product-setup/create-add-ons-for-products",
          subPageTitle: "Create add-ons for products",
        },
        {
          menuKey: "manage-multiple-pricing-for-products-using-price-book",
          Navlink:
            "product-setup/manage-multiple-pricing-for-products-using-price-book",
          subPageTitle: "Manage Multiple Pricing for...",
        },
        {
          menuKey: "limit-a-product-to-a-particular-register-shop",
          Navlink:
            "product-setup/limit-a-product-to-a-particular-register-shop",
          subPageTitle: "Limit a product to a particular...",
        },
        {
          menuKey: "add-Combo-products",
          Navlink: "product-setup/add-Combo-products",
          subPageTitle: "Add Combo Products",
        },
      ],
    },
    AddProductCat: {
      pagetitle: "Product Setup",
      pageLink: "product-setup",
      subPageTitle: "Add product categories",
      content: [
        {
          para: "Make product selection easier by creating product categories",
        },
        {
          para: "1. Click on setup on left bottom of the screen.",
        },
        {
          para: '2. Select "Product categories"',
        },
        {
          para: '3. Select Add "product category" to add product categories.',
        },
        {
          img: AddProductCat1,
        },
      ],
      Navbar: [
        {
          menuKey: "add-edit-delete-product",
          Navlink: "product-setup/add-edit-delete-product",
          subPageTitle: "Add, Edit & Delete products",
        },
        {
          menuKey: "create-variants",
          Navlink: "product-setup/create-variants",
          subPageTitle: "Create variants for products",
        },
        {
          menuKey: "bulk-import-export-products",
          Navlink: "product-setup/bulk-import-export-products",
          subPageTitle: "Bulk import & export of products",
        },
        {
          menuKey: "add-product-categories",
          Navlink: "product-setup/add-product-categories",
          subPageTitle: "Add product categories",
        },
        {
          menuKey: "create-add-ons-for-products",
          Navlink: "product-setup/create-add-ons-for-products",
          subPageTitle: "Create add-ons for products",
        },
        {
          menuKey: "manage-multiple-pricing-for-products-using-price-book",
          Navlink:
            "product-setup/manage-multiple-pricing-for-products-using-price-book",
          subPageTitle: "Manage Multiple Pricing for...",
        },
        {
          menuKey: "limit-a-product-to-a-particular-register-shop",
          Navlink:
            "product-setup/limit-a-product-to-a-particular-register-shop",
          subPageTitle: "Limit a product to a particular...",
        },
        {
          menuKey: "add-Combo-products",
          Navlink: "product-setup/add-Combo-products",
          subPageTitle: "Add Combo Products",
        },
      ],
    },
    ImportExportProduct: {
      pagetitle: "Product Setup",
      pageLink: "product-setup",
      subPageTitle: "Bulk import & export of products",
      content: [
        {
          para: "Go ahead import and export your products in one shot",
        },
        {
          para: "1. Select products from the menu. Then click on import.",
        },
        {
          img: ImportExportProduct1,
        },
        {
          para: "2. Download the template for product import.",
        },
        {
          img: ImportExportProduct2,
        },
        {
          para: "3. Fill product details in the template",
        },
        {
          img: ImportExportProduct3,
        },
        {
          para: "4. Select the product csv file and click on preview",
        },
        {
          img: ImportExportProduct4,
        },
        {
          para: "5. Preview the products, the preview would show if there are error records. They wont be uploaded. Correct the file and upload",
        },
        {
          img: ImportExportProduct5,
        },
        {
          para: "6. Products are imported seamlessly.",
        },
        {
          img: ImportExportProduct6,
        },
        {
          para: "7. Product Export: Export your products to store or to make changes and re-upload.",
        },
        {
          img: ImportExportProduct7,
        },
      ],
      Navbar: [
        {
          menuKey: "add-edit-delete-product",
          Navlink: "product-setup/add-edit-delete-product",
          subPageTitle: "Add, Edit & Delete products",
        },
        {
          menuKey: "create-variants",
          Navlink: "product-setup/create-variants",
          subPageTitle: "Create variants for products",
        },
        {
          menuKey: "bulk-import-export-products",
          Navlink: "product-setup/bulk-import-export-products",
          subPageTitle: "Bulk import & export of products",
        },
        {
          menuKey: "add-product-categories",
          Navlink: "product-setup/add-product-categories",
          subPageTitle: "Add product categories",
        },
        {
          menuKey: "create-add-ons-for-products",
          Navlink: "product-setup/create-add-ons-for-products",
          subPageTitle: "Create add-ons for products",
        },
        {
          menuKey: "manage-multiple-pricing-for-products-using-price-book",
          Navlink:
            "product-setup/manage-multiple-pricing-for-products-using-price-book",
          subPageTitle: "Manage Multiple Pricing for...",
        },
        {
          menuKey: "limit-a-product-to-a-particular-register-shop",
          Navlink:
            "product-setup/limit-a-product-to-a-particular-register-shop",
          subPageTitle: "Limit a product to a particular...",
        },
        {
          menuKey: "add-Combo-products",
          Navlink: "product-setup/add-Combo-products",
          subPageTitle: "Add Combo Products",
        },
      ],
    },
    LimitProductToShop: {
      pagetitle: "Product Setup",
      pageLink: "product-setup",
      subPageTitle: "Limit a product to a particular Register/ Shop",
      content: [
        {
          para: "If you are running multiple outlets and using multiple registers in Posease, you can optionally choose to limit a product to a particular shop or register. This is useful if you have shop specific products and you do not want such products to be visible across all shops. This can be configured in product setup or by product import.",
        },
        {
          para: "1. Select the added product and link it to a particular register.",
        },
        {
          img: LimitProductToShop1,
        },
        {
          img: LimitProductToShop2,
        },
        {
          img: LimitProductToShop3,
        },
      ],
      Navbar: [
        {
          menuKey: "add-edit-delete-product",
          Navlink: "product-setup/add-edit-delete-product",
          subPageTitle: "Add, Edit & Delete products",
        },
        {
          menuKey: "create-variants",
          Navlink: "product-setup/create-variants",
          subPageTitle: "Create variants for products",
        },
        {
          menuKey: "bulk-import-export-products",
          Navlink: "product-setup/bulk-import-export-products",
          subPageTitle: "Bulk import & export of products",
        },
        {
          menuKey: "add-product-categories",
          Navlink: "product-setup/add-product-categories",
          subPageTitle: "Add product categories",
        },
        {
          menuKey: "create-add-ons-for-products",
          Navlink: "product-setup/create-add-ons-for-products",
          subPageTitle: "Create add-ons for products",
        },
        {
          menuKey: "manage-multiple-pricing-for-products-using-price-book",
          Navlink:
            "product-setup/manage-multiple-pricing-for-products-using-price-book",
          subPageTitle: "Manage Multiple Pricing for...",
        },
        {
          menuKey: "limit-a-product-to-a-particular-register-shop",
          Navlink:
            "product-setup/limit-a-product-to-a-particular-register-shop",
          subPageTitle: "Limit a product to a particular...",
        },
        {
          menuKey: "add-Combo-products",
          Navlink: "product-setup/add-Combo-products",
          subPageTitle: "Add Combo Products",
        },
      ],
    },
    ManagePricing: {
      pagetitle: "Product Setup",
      pageLink: "product-setup",
      subPageTitle: "Manage Multiple Pricing for Products using Price Book",
      content: [
        {
          para: "1. Click on Add Price Book button to create a new Price Book.",
        },
        {
          img: manage1,
        },
        {
          para: "2. You can restrict the changes made in the price book for a particular register and also for a particular order type",
        },
        {
          img: manage2,
        },
        {
          para: "The above Price Book configuration, Price Book 1 applies for all orders under the Main Register. The changes made in Price Book 1 will be applied only for Orders from Main Register and will not reflect in other registers.",
        },
        {
          para: "4. Also manage price book for different table sections.",
        },
        {
          img: manage3,
        },
        {
          para: "5. Click on the arrow mark below Manage Items to modify product pricing and availability.",
        },
        {
          img: manage4,
        },
      ],
      Navbar: [
        {
          menuKey: "add-edit-delete-product",
          Navlink: "product-setup/add-edit-delete-product",
          subPageTitle: "Add, Edit & Delete products",
        },
        {
          menuKey: "create-variants",
          Navlink: "product-setup/create-variants",
          subPageTitle: "Create variants for products",
        },
        {
          menuKey: "bulk-import-export-products",
          Navlink: "product-setup/bulk-import-export-products",
          subPageTitle: "Bulk import & export of products",
        },
        {
          menuKey: "add-product-categories",
          Navlink: "product-setup/add-product-categories",
          subPageTitle: "Add product categories",
        },
        {
          menuKey: "create-add-ons-for-products",
          Navlink: "product-setup/create-add-ons-for-products",
          subPageTitle: "Create add-ons for products",
        },
        {
          menuKey: "manage-multiple-pricing-for-products-using-price-book",
          Navlink:
            "product-setup/manage-multiple-pricing-for-products-using-price-book",
          subPageTitle: "Manage Multiple Pricing for...",
        },
        {
          menuKey: "limit-a-product-to-a-particular-register-shop",
          Navlink:
            "product-setup/limit-a-product-to-a-particular-register-shop",
          subPageTitle: "Limit a product to a particular...",
        },
        {
          menuKey: "add-Combo-products",
          Navlink: "product-setup/add-Combo-products",
          subPageTitle: "Add Combo Products",
        },
      ],
    },
  },
  ReceiptSetup: {
    Navbar: [
      {
        menuKey: "editing-receipt-header-footer-disabling-print",
        Navlink: "receipt-setup/editing-receipt-header-footer-disabling-print",
        subPageTitle: "Editing receipt header, footer & Disabling print",
      },
      {
        menuKey: "cancel-delete-receipt",
        Navlink: "receipt-setup/cancel-delete-receipt",
        subPageTitle: "Cancel & Delete receipt",
      },
      {
        menuKey: "enable-strict-receipt-number",
        Navlink: "receipt-setup/enable-strict-receipt-number",
        subPageTitle: "Enable strict receipt number",
      },
    ],
    editReceipt: {
      pagetitle: "Receipt Setup",
      pageLink: "receipt-setup",
      subPageTitle: "Editing receipt header, footer & Disabling print",
      content: [
        {
          para: "Customize your receipts as per requirements. Add contact details, offers, information to customers etc. on the receipt.",
        },
        {
          para: "1. Click on setup on left bottom of the screen.",
        },
        {
          para: '2. Select "Registers".',
        },
        {
          para: "3. Select register for which bill needs to be updated.",
        },
        {
          para: "4. Update bill header and footer as required.",
        },
        {
          para: '5. Deselect "Enable receipt printing" to disable printing.',
        },
        {
          img: editReceipt,
        },
      ],
      Navbar: [
        {
          menuKey: "editing-receipt-header-footer-disabling-print",
          Navlink:
            "receipt-setup/editing-receipt-header-footer-disabling-print",
          subPageTitle: "Editing receipt header, footer...",
        },
        {
          menuKey: "cancel-delete-receipt",
          Navlink: "receipt-setup/cancel-delete-receipt",
          subPageTitle: "Cancel & Delete receipt",
        },
        {
          menuKey: "enable-strict-receipt-number",
          Navlink: "receipt-setup/enable-strict-receipt-number",
          subPageTitle: "Enable strict receipt number",
        },
      ],
    },
    cancelDeleteReceipt: {
      pagetitle: "Receipt Setup",
      pageLink: "receipt-setup",
      subPageTitle: "Cancel & Delete receipt",
      content: [
        {
          para: "There is every chance a bill is incorrectly entered or cancelled. You can cancel the bill and Posease tracks the cancelled bills to make sure fraud is avoided.",
        },
        {
          para: "1. Click receipts on side bar.",
        },
        {
          img: cancelDeleteReceipt1,
        },
        {
          para: "2. Receipt details are displayed. Click on cancel to void the receipt. This will keep the receipt in the POS with cancelled status.",
        },
        {
          img: cancelDeleteReceipt2,
        },
        {
          para: "3. Enter the amount to refund in the receipt. Click cancel. The amount will be reduced from sales.",
        },
        {
          img: cancelDeleteReceipt3,
        },
        {
          para: "4. The receipt is cancelled. If you need to completely remove the receipt from the POS, click delete",
        },
        {
          img: cancelDeleteReceipt4,
        },
        {
          para: "5. Click on delete.",
        },
        {
          img: cancelDeleteReceipt5,
        },
        {
          para: "6. The receipt will be no longer available in the system.",
        },
        {
          img: cancelDeleteReceipt6,
        },
      ],
      Navbar: [
        {
          menuKey: "editing-receipt-header-footer-disabling-print",
          Navlink:
            "receipt-setup/editing-receipt-header-footer-disabling-print",
          subPageTitle: "Editing receipt header, footer...",
        },
        {
          menuKey: "cancel-delete-receipt",
          Navlink: "receipt-setup/cancel-delete-receipt",
          subPageTitle: "Cancel & Delete receipt",
        },
        {
          menuKey: "enable-strict-receipt-number",
          Navlink: "receipt-setup/enable-strict-receipt-number",
          subPageTitle: "Enable strict receipt number",
        },
      ],
    },
    enableReceiptNo: {
      pagetitle: "Receipt Setup",
      pageLink: "receipt-setup",
      subPageTitle: "Enable strict receipt number",
      content: [
        {
          para: "If your government regulations say that the receipt number on the bill should be exactly the same as the one submitted in the filings. Also these receipt numbers should be in a sterilized format for the whole year. Then you should have strict receipt numbers enabled",
        },
        {
          para: "The key thing is you should not bill on multiple systems for a single register in Posease.",
        },
        {
          para: "1. Click on setup on left bottom of the screen.",
        },
        {
          para: '2. Select "preferences". Now enable sequential local receipt numbers.',
        },
        {
          img: enableReceiptNo1,
        },
        {
          para: "3. Once you go to sell now it will give you a warning, to not be logged in multiple systems to bill.",
        },
        {
          img: enableReceiptNo2,
        },
        {
          para: "4. if you switch systems, make sure the last receipt number is correct. Before you start billing in a new system.",
        },
        {
          img: enableReceiptNo3,
        },
      ],
      Navbar: [
        {
          menuKey: "editing-receipt-header-footer-disabling-print",
          Navlink:
            "receipt-setup/editing-receipt-header-footer-disabling-print",
          subPageTitle: "Editing receipt header, footer...",
        },
        {
          menuKey: "cancel-delete-receipt",
          Navlink: "receipt-setup/cancel-delete-receipt",
          subPageTitle: "Cancel & Delete receipt",
        },
        {
          menuKey: "enable-strict-receipt-number",
          Navlink: "receipt-setup/enable-strict-receipt-number",
          subPageTitle: "Enable strict receipt number",
        },
      ],
    },
  },
  Billing: {
    Navbar: [
      {
        menuKey: "create-kots-to-manage-your-kitchen-orders",
        Navlink:
          "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
        subPageTitle: "Create KOT's to manage your...",
      },
      {
        menuKey: "create-kots-to-manage-your-kitchen-orders",
        Navlink: "billing-taking-order/table-management-take-orders-by-table",
        subPageTitle: "Table management, take order...",
      },
      {
        menuKey: "shift-openclose-day-end-calculations",
        Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
        subPageTitle: "Shift open/close, day end...",
      },
      {
        menuKey: "know-all-about-selling",
        Navlink: "billing-taking-order/know-all-about-selling",
        subPageTitle: "Know all about selling",
      },
      {
        menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
        Navlink:
          "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
        subPageTitle: "Print receipt before payment...",
      },
      {
        menuKey: "credit-sales",
        Navlink: "billing-taking-order/credit-sales",
        subPageTitle: "Credit sales",
      },
      {
        menuKey: "hold-bill",
        Navlink: "billing-taking-order/hold-bill",
        subPageTitle: "Hold bill",
      },
      {
        menuKey: "quick-billing-generate-the-bill-in-one-click",
        Navlink:
          "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
        subPageTitle: "Quick billing, generate the bill...",
      },
    ],
    manageKitchen: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Create KOT's to manage your kitchen orders",
      content: [
        {
          para: "Serving your customer orders at the right time is key to a happy customer. KOT's help you serialize your orders",
        },
        {
          para: "To enable the KOT feature go to preferences. Refer the below link for more guidance.",
        },
        {
          nav: "posease-setup/customize-your-pos-with-preferences",
        },
        {
          para: "Once the KOT feature is enabled the sell screen would have a order ticket button.",
        },
        {
          img: manageKitchen1,
        },
        {
          para: "As a order is taken, a KOT can be printed without generating a receipt by clicking on Order Ticket.",
        },
        {
          img: manageKitchen2,
        },
        {
          para: "You can enter a draft name for a rolling order and also include special instructions for the order in the KOT. Click on Create Order Ticket to print the KOT",
        },
        {
          img: manageKitchen3,
        },
        {
          para: "KOT can also be generated if the order is billed directly without waiting. The KOT is printed along with the final receipt.",
        },
        {
          para: "If Items are removed after KOT is generated. A cancelled KOT can also be printed by clicking on Create Order Ticket.",
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    tableManagement: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Table management, take orders by table",
      content: [
        {
          para: "Manage your orders by table, also take care of takeaways and deliveries separately.",
        },
        {
          para: "Table management can be enabled for each register (shop) separately.",
        },
        {
          para: "To enable table management go to the respective register.",
        },
        {
          img: tableManagement1,
        },
        {
          para: "In the selected register scroll down to table numbers.",
        },
        {
          img: tableManagement2,
        },
        {
          para: "You can enter the table numbers separated by commas or to give a range give the start and end number (numerical only allowed) separated by a hyphen eg. 1-10.",
        },
        {
          img: tableManagement3,
        },
        {
          para: "Once done the tables are ready to take order. You can select the table and take orders.",
        },
        {
          img: tableManagement4,
        },
        {
          para: "If different customers sit at the same table, you can split the table and make separate bills.",
        },
        {
          img: tableManagement5,
        },
        {
          img: tableManagement6,
        },
        {
          img: tableManagement7,
        },
        {
          para: "To Swap from one to another, click on the processing table order and click Swap -> Destination Table -> Swap Table to finish process.",
        },
        {
          img: tableManagement8,
        },
        {
          img: tableManagement9,
        },
        {
          img: tableManagement10,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    shiftOpenClose: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Shift open/close, day end calculations",
      content: [
        {
          para: "Do you want reconcile accounts at a end of shift or end of the day, use the shift open/close feature.",
        },
        {
          para: "1. You can start a shift once you login in.",
        },
        {
          img: shiftOpenClose1,
        },
        {
          para: "2. Enter the starting cash balance and unlock to start shift..",
        },
        {
          img: shiftOpenClose2,
        },
        {
          para: "3. Continue billing. Once shift.day is done. Lock to close shift.",
        },
        {
          img: shiftOpenClose3,
        },
        {
          para: '4. Select "End shift" check box.',
        },
        {
          img: shiftOpenClose4,
        },
        {
          para: "5. Enter the cash denominations and click lock.",
        },
        {
          img: shiftOpenClose5,
        },
        {
          para: "6. Click on print shift summary to see account reconciliation and to print it.",
        },
        {
          img: shiftOpenClose6,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    aboutSelling: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Know all about selling",
      content: [
        {
          para: "In sell screen select products you want to add to current sale. To search for a product type any part of the product name to get shortlist of items.",
        },
        {
          img: aboutSelling1,
        },
        {
          para: "Adding product discount and bulk discount in the bill",
        },
        {
          img: aboutSelling2,
        },
        {
          para: "Click charge to select payment type.",
        },
        {
          img: aboutSelling3,
        },
        {
          para: "Using Split Payments you can use different modes of payment for a single order.",
        },
        {
          para: "Select split payments option in the payments screen to use split payments method.",
        },
        {
          img: aboutSelling4,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    printReceipt: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Print receipt before payment, for dine-in restaurants",
      content: [
        {
          para: "In dine-in restaurants the bill is first printed to receive the payment from customer. Then the payment type cash or credit card is chosen based on the payment.",
        },
        {
          para: "To enable the feature go to settings->preferences",
        },
        {
          img: printReceipt1,
        },
        {
          para: "Add items to bill and click on charge.",
        },
        {
          img: printReceipt2,
        },
        {
          para: "Print the receipt",
        },
        {
          img: printReceipt3,
        },
        {
          para: "Once the customer gives the payment, select the type of payment.",
        },
        {
          img: printReceipt4,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    creditSales: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Credit sales",
      content: [
        {
          para: "Businesses always get payments at a later point of time. Make them as credit sales and track the receipts that are unpaid.",
        },
        {
          para: "1. Add products for the sale. Click charge for next screen.",
        },
        {
          para: "2. Select Credit Sales from drop down . Customer phone and name are mandatory fields for credit sale.",
        },
        {
          img: creditSales1,
        },
        {
          para: "3. View Credit receipts",
          subpara: [
            {
              subpara: "Click receipts on side bar.",
            },
            {
              subpara: "Select the unpaid tab.",
            },
            {
              subpara:
                "Select the date range in which you want to look at credit receipts.",
            },
          ],
        },
        {
          img: creditSales2,
        },
        {
          para: "4. Select receipt to receive the payment.",
        },
        {
          img: creditSales3,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    holdBill: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Hold bill",
      content: [
        {
          para: "If you want to hold bill as he customer is going to order more. You can use the hold option.",
        },
        {
          para: "1. To save a bill as draft select the Save & New option.",
        },
        {
          img: holdBill1,
        },
        {
          para: "2. Enter the name of the draft eg. Table 2",
        },
        {
          img: holdBill2,
        },
        {
          para: "3. To select a draft to bill, select the Drafts tab and select the bill.",
        },
        {
          img: holdBill3,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    quickBilling: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Quick billing, generate the bill in one click",
      content: [
        {
          para: "Sometimes your billing speed is critical to your business operation. You don't want to enter details like type of payment, customer details etc. You can go for the quick billing option.",
        },
        {
          para: "Go to settings > preferences, enable quick billing and save",
        },
        {
          img: quickBilling1,
        },
        {
          para: "Now once you add items to a bill and click on charge, the bill will get generated immediately.",
        },
        {
          img: quickBilling2,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    applyCodes: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Apply Coupon codes in billing",
      content: [],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    // checkWaiter: {
    //   pagetitle: "Billing, taking orders",
    //   pageLink: "billing-taking-order",
    //   subPageTitle: "Check For Waiter Active Or Not",
    //   content: [],
    //   Navbar: [
    //     {
    //       menuKey: "create-kots-to-manage-your-kitchen-orders",
    //       Navlink:
    //         "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
    //       subPageTitle: "Create KOT's to manage your...",
    //     },
    //     {
    //       menuKey: "table-management-take-orders-by-table",
    //       Navlink: "billing-taking-order/table-management-take-orders-by-table",
    //       subPageTitle: "Table management, take order...",
    //     },
    //     {
    //       menuKey: "shift-openclose-day-end-calculations",
    //       Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
    //       subPageTitle: "Shift open/close, day end...",
    //     },
    //     {
    //       menuKey: "know-all-about-selling",
    //       Navlink: "billing-taking-order/know-all-about-selling",
    //       subPageTitle: "Know all about selling",
    //     },
    //     {
    //       menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
    //       Navlink:
    //         "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
    //       subPageTitle: "Print receipt before payment...",
    //     },
    //     {
    //       menuKey: "credit-sales",
    //       Navlink: "billing-taking-order/credit-sales",
    //       subPageTitle: "Credit sales",
    //     },
    //     {
    //       menuKey: "hold-bill",
    //       Navlink: "billing-taking-order/hold-bill",
    //       subPageTitle: "Hold bill",
    //     },
    //     {
    //       menuKey: "quick-billing-generate-the-bill-in-one-click",
    //       Navlink:
    //         "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
    //       subPageTitle: "Quick billing, generate the bill...",
    //     },
    //   ],
    // },
    tableSwap: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Table swap in web admin panel.",
      content: [
        {
          para: "If customer moves from one table to another, the orders can be swapped using our swap option.",
        },
        {
          para: "1. Select the table and take order.",
        },
        {
          img: tableSwap1,
        },
        {
          para: "2. Click on swap and select a table.",
        },
        {
          img: tableSwap2,
        },
        {
          para: "3. The table is now swapped.",
        },
        {
          img: tableSwap3,
        },
        {
          img: tableSwap4,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    SectionTable: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Manage Tables based on Sections",
      content: [
        {
          para: "Create Table sections to manage tables from multiple sections of your shop.",
        },
        {
          para: "Table Sections can be created for each register (shop) separately.",
        },
        {
          para: "1. To create table sections go to the respective register",
        },
        {
          img: SectionTable1,
        },
        {
          para: "2. In the selected register scroll down to table numbers.",
        },
        {
          para: "3. You can enter the table sections and the table numbers separated by commas in the following format: table(1 to 10)",
        },
        {
          img: SectionTable2,
        },
        {
          para: "Once done the tables sections are ready to take orders. You can select the table section and take orders from the desired table.",
        },
        {
          img: SectionTable3,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    splitBill: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle:
        "Split Payments: Receive payments from multiple payment modes",
      content: [
        {
          para: "Finding difficulties in billing the payment received from different payment modes ?",
        },
        {
          para: "You can use split payment option to enter the payments received from different sources into the same bill.",
        },
        {
          para: "Go on Sell, add the required products and click on charge to move to the payment scree",
        },
        {
          img: splitBill1,
        },
        {
          para: "Click on Split payment to mark payments received from different payment modes.",
        },
        {
          img: splitBill2,
        },
        {
          para: "Enter the amount in the required payment modes. You can view the pending / excess amount on top of the payment modes.",
        },
        {
          img: splitBill3,
        },
        {
          para: "Now click on Update to add the split payments. You will be able to update the payment only when there are no pending amount to be settled.",
        },
        {
          img: splitBill4,
        },
        {
          para: "Now click on Received to complete the Bill.",
        },
        {
          img: splitBill5,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    addingDelivery: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Adding Delivery Details For Phone Customers.",
      content: [
        {
          para: "Add the details of the Phone Orders of the customer without any difficulties. Get the details from the customer and add them as and then when the orders are being placed.",
        },
        {
          para: "1. Select Take away from the sell screen.",
        },
        {
          img: addingDelivery1,
        },
        {
          para: "2. Select the product ordered by the customer and add their details by clicking on Add Customer.",
        },
        {
          img: addingDelivery2,
        },
        {
          para: "3. Enter the general details of the customer like Phone number and name in General Tab.",
        },
        {
          img: addingDelivery3,
        },
        {
          para: "4. Enter the shipping address in the Delivery Tab.",
        },
        {
          img: addingDelivery4,
        },
        {
          para: "5. Click Charge to proceed further.",
        },
        {
          img: addingDelivery5,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    splitBillbasedOnItem: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Split payment based on items wise",
      content: [
        {
          para: "Please refer the following steps to split / share the payments.",
        },
        {
          para: "Customer wise split payments:",
        },
        {
          para: '1. Go to "Sell" tab to enter the billing items. Click on "Charge" to split billing.',
        },
        {
          img: splitBillbasedOnItem1,
        },
        {
          para: '2. Click on "Split" button.',
        },
        {
          img: splitBillbasedOnItem2,
        },
        {
          para: '3. Select "By Items" button to enter number of customers and their details.',
        },
        {
          img: splitBillbasedOnItem3,
        },
        {
          para: '4: Click on "Print receipt" to split the payment of items. Assign items to the customers.',
        },
        {
          img: splitBillbasedOnItem4,
        },
        {
          img: splitBillbasedOnItem5,
        },
        {
          para: "5: Select the payment types of each customer and complete the payment.",
        },
        {
          img: splitBillbasedOnItem6,
        },
        {
          para: "Customers can share their billing price equally and complete the payments.",
        },
        {
          para: "Note: This option is useful for team / friends",
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    splitBillBasedOnCustomers: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Split payments based upon customers",
      content: [
        {
          para: "You can allow your customer to split / share the payments in two methods.",
        },
        {
          para: "1. Number of customers",
        },
        {
          para: "2. Number of items.",
        },
        {
          para: "Please refer the following steps to split / share the payments.",
        },
        {
          para: "Customer wise split payments:",
        },
        {
          para: 'Step 1: Go to "Sell" tab to enter the billing items. Click on "Charge" to split billing.',
        },
        {
          img: splitBillBasedOnCustomers1,
        },
        {
          para: 'Step 2: Click on "Split" button.',
        },
        {
          img: splitBillBasedOnCustomers2,
        },
        {
          para: 'Step 3: Select "Equally" button to enter number of customers and details.',
        },
        {
          img: splitBillBasedOnCustomers3,
        },
        {
          para: 'Step 4: Click on "Print receipt" to split the payment of customers equally. After that select the payment type of each customer and complete the split payment.',
        },
        {
          img: splitBillBasedOnCustomers4,
        },
        {
          para: "Customers can share their billing price equally and complete the payments.",
        },
        {
          para: "Note: This option is useful for team / friends",
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
    PettyCash: {
      pagetitle: "Billing, taking orders",
      pageLink: "billing-taking-order",
      subPageTitle: "Manage expenses in your store in Petty Cash",
      content: [
        {
          para: "Enter expenses or reconcile cash in Cash out at the end of the day.",
        },
        {
          para: "1. To add an Cash in/Cash out. Click on Add Petty Cash.",
        },
        {
          img: PettyCash1,
        },
        {
          para: "2. Enter The Cash in or Cash out",
        },
        {
          img: PettyCash2,
        },
        {
          para: "3. The Cash Out is Added. To get the Petty Cash report. Click on Export to get the report.",
        },
        {
          img: PettyCash3,
        },
        {
          para: "4. Click on Export. Select the Date range, Register and click Ok.",
        },
        {
          img: PettyCash4,
        },
      ],
      Navbar: [
        {
          menuKey: "create-kots-to-manage-your-kitchen-orders",
          Navlink:
            "billing-taking-order/create-kots-to-manage-your-kitchen-orders",
          subPageTitle: "Create KOT's to manage your...",
        },
        {
          menuKey: "table-management-take-orders-by-table",
          Navlink: "billing-taking-order/table-management-take-orders-by-table",
          subPageTitle: "Table management, take order...",
        },
        {
          menuKey: "shift-openclose-day-end-calculations",
          Navlink: "billing-taking-order/shift-openclose-day-end-calculations",
          subPageTitle: "Shift open/close, day end...",
        },
        {
          menuKey: "know-all-about-selling",
          Navlink: "billing-taking-order/know-all-about-selling",
          subPageTitle: "Know all about selling",
        },
        {
          menuKey: "print-receipt-before-payment-for-dine-in-restaurants",
          Navlink:
            "billing-taking-order/print-receipt-before-payment-for-dine-in-restaurants",
          subPageTitle: "Print receipt before payment...",
        },
        {
          menuKey: "credit-sales",
          Navlink: "billing-taking-order/credit-sales",
          subPageTitle: "Credit sales",
        },
        {
          menuKey: "hold-bill",
          Navlink: "billing-taking-order/hold-bill",
          subPageTitle: "Hold bill",
        },
        {
          menuKey: "quick-billing-generate-the-bill-in-one-click",
          Navlink:
            "billing-taking-order/quick-billing-generate-the-bill-in-one-click",
          subPageTitle: "Quick billing, generate the bill...",
        },
      ],
    },
  },
  poseaseSetup: {
    Navbar: [
      {
        menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
        Navlink:
          "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
        subPageTitle: "How to enable and use Wai...",
      },
      {
        menuKey: "customize-your-pos-with-preferences",
        Navlink: "posease-setup/customize-your-pos-with-preferences",
        subPageTitle: "Customize your POS with...",
      },
      {
        menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
        Navlink:
          "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
        subPageTitle: "Employee access control...",
      },
      {
        menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
        Navlink:
          "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
        subPageTitle: "Add single tax or multiple taxe...",
      },
      {
        menuKey: "add-multiple-registersshops-in-one-accounts",
        Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
        subPageTitle: "Add multiple registers(shops)...",
      },
      {
        menuKey: "owner-pin-logo-setup",
        Navlink: "posease-setup/owner-pin-logo-setup",
        subPageTitle: "Owner pin, Logo setup",
      },
      {
        menuKey: "setup-additional-charges-parcel-delivery-service-charge-etc",
        Navlink:
          "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
        subPageTitle: "Setup additional charges...",
      },
      {
        menuKey: "getting-started",
        Navlink: "posease-setup/getting-started",
        subPageTitle: "Getting started",
      },
      {
        menuKey: "create-custom-payment-types",
        Navlink: "posease-setup/create-custom-payment-types",
        subPageTitle: "Create custom payment types",
      },
    ],
    CustomizePreferences: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Customize your POS with preferences",
      content: [
        {
          para: "Require few customization to enhance usage of Posease? Customized to your requirements by changing the preferences.",
        },
        {
          para: "1. Click on Setting to view preferences.",
        },
        {
          img: CustomizePreferences1,
        },
        {
          para: "2. Click on preferences to customize selling, printing and permission preferences.",
        },
        {
          img: CustomizePreferences2,
        },
        {
          para: "3. Selling Preferences.",
        },
        {
          img: CustomizePreferences3,
        },
        {
          para: "4. Printing Preferences.",
        },
        {
          img: CustomizePreferences4,
        },
        {
          para: "5. Permission Preferences.",
        },
        {
          img: CustomizePreferences5,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    EmpAccessControl: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle:
        "Employee access control: Cashier/Manager mode (Avoid fraud)",
      content: [
        {
          para: 'The best practice for your business is to never sell in "Admin mode". Create cashier/manager accounts for your employees to avoid fraud.',
        },
        {
          para: "To add/change admin pin, refer the below link",
        },
        {
          nav: "posease-setup/owner-pin-logo-setup",
        },
        {
          para: "1. Click on setting and select cashiers to add cashier/manager",
        },
        {
          img: EmpAccessControl1,
        },
        {
          para: '2. Add cashier/manager name, pin and select the register (shop) they are working in. Select the "Allow manager permissions" if the employee needs manager permissions.',
        },
        {
          img: EmpAccessControl2,
        },
        {
          para: "3. Once all setup is done in the POS. Lock the screen, employees can now login with their respective cashier/manager pin.",
        },
        {
          img: EmpAccessControl3,
        },
        {
          img: EmpAccessControl4,
        },
        {
          para: "Cashiers have access only to sell, view bookings/credit sales that needs to be fulfilled.",
        },
        {
          img: EmpAccessControl5,
        },
        {
          para: "Managers have access to see dashboard, receipts, customers, inventory management and SMS app. They don't have access to any setup.",
        },
        {
          img: EmpAccessControl6,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    singleMultiTax: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Add single tax or multiple taxes (GST) to products",
      content: [
        {
          para: "Create tax groups, add tax to tax groups, assign to products. Don't worry if tax regulations change, changing tax is a breeze.",
        },
        {
          para: "1. Click on Profile on Right Top of the screen.And clck on setting",
        },
        {
          para: "2. Select 'Taxes' . You can required create taxes, then assign it to tax groups created. Then attached to products in product screen.",
        },
        {
          img: singleMultiTax1,
        },
        {
          para: "3. Create required taxes",
        },
        {
          img: singleMultiTax2,
        },
        {
          para: "4. Select 'Tax Group' Tab and click to Create Taxes Group",
        },
        {
          img: singleMultiTax4,
        },
        {
          img: singleMultiTax5,
        },
        {
          para: "5. Create tax group and assign taxes to it.",
        },
        {
          img: singleMultiTax3,
        },
        {
          para: "The tax group can now assigned to products in the product page.",
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    addMultiReg: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Add multiple registers(shops) in one account",
      content: [
        {
          para: "You can manage all your shops or registers from one admin account. Get a holistic view of all your shops in one place anytime anywhere.",
        },
        {
          para: "You can create a new register as follows.",
        },
        {
          para: "1. Click on Profile on Right Top of the screen.And clck on setting",
        },
        {
          para: '2. Select "Registers".',
        },
        {
          para: '3. Select "Add register" to add a new register.',
        },
        {
          img: addMultiReg1,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    additionalCharge: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle:
        "Setup additional charges (parcel, delivery, service charge etc.)",
      content: [
        {
          para: "All businesses levy additional charges like parcel, delivery, service etc. Create them and add it to the receipts in a click.",
        },
        {
          para: "1. Click on Profile on Right Top of the screen.And clck on setting",
        },
        {
          para: "2. Click on additional charge and then add additional charge,",
        },
        {
          img: additionalCharge1,
        },
        {
          para: "3. Enter the additional charge type and details. Then save it.",
        },
        {
          img: additionalCharge2,
        },
        {
          img: additionalCharge3,
        },
        {
          para: "4. Now while selling additional charge can be added as per requirement",
        },
        {
          img: additionalCharge4,
        },
        {
          img: additionalCharge5,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    ownerPin: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Owner pin, Logo setup",
      content: [
        {
          para: "Secure your account from employees by creating a owner pin (Keep it protected). You can also upload a logo that can be printed on the receipt.",
        },
        {
          para: "1. Click on Profile on Right Top of the screen.And clck on setting",
        },
        {
          para: '2. Select "Shop"',
        },
        {
          img: ownerPin1,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    gettingStarted: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Getting started",
      content: [
        {
          para: "Welcome to Posease. Use the below link to sign into Posease",
        },
        {
          http: "https://web.Posease.com/",
        },
        {
          para: "You can signup by using one of following two methods ​",
        },
        {
          img: gettingStarted1,
        },
        {
          para: "Select your type of business, so that we can configure the system accordingly",
        },
        {
          img: gettingStarted2,
        },
        {
          para: "Enter business details as requested and you are ready to sell with our sample products !",
        },
        {
          img: gettingStarted3,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    customPaymentType: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Create custom payment types",
      content: [
        {
          para: "With more and more payment providers coming into the market. Customers have many options to pay from. Now create payment types as per requirement in Posease.",
        },
        {
          para: "To create payment types go to setting->custom fields. Click add payment type or custom field.",
        },
        {
          img: customPaymentType1,
        },
        {
          img: customPaymentType2,
        },
        {
          para: "Now the new payment type will appear during sell for you to use.",
        },
        {
          img: customPaymentType3,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    EmployeeAccess: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Employee access control:App user",
      content: [
        {
          para: "Posease allows you to create an App User ID exclusively for handling apps like Inventory, Analytics, etc.",
        },
        {
          para: "1. To create an App User ID Click settings -> Users -> All App Users -> Add App User .",
        },
        {
          img: EmployeeAccess1,
        },
        {
          para: "2. Enter the User Name and Password .",
        },
        {
          img: CreateManagerCashiers4,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    AddTags: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Add tags to sale or customer",
      content: [
        {
          para: "Tag orders or customers to easily segregate them.",
        },
        {
          para: "1. To create custom tags to setting -> custom fields",
        },
        {
          img: AddTags1,
        },
        {
          para: "2. Click on add custom field.",
        },
        {
          img: AddTags2,
        },
        {
          para: "3. Type in the name of the tag and select the type of tag, then click on save.",
        },
        {
          img: AddTags3,
        },
        {
          para: "4. While billing the custom tag can be selected, depending on the order.",
        },
        {
          img: AddTags4,
        },
        {
          img: AddTags5,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    CreateCustomes: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Create custom sales and customer fields",
      content: [
        {
          para: "It is always good to collect as much data as possible about the sale and customer. It helps in knowing how the business is performing and buying pattern of customers to improve sales in the future.",
        },
        {
          para: "1. Click on setting and select custom fields.",
        },
        {
          img: CreateCustomes1,
        },
        {
          para: "2. Enter Payment Type field name and description.in the Payment type tab",
        },
        {
          img: CreateCustomes2,
        },
        {
          para: "3. Enter Additional Details field name and type of data.in the additional Detail tab",
        },
        {
          img: CreateCustomes3,
        },
        {
          para: "4. Enter Tag field name and type of data.in the tag tab",
        },
        {
          img: CreateCustomes4,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    KitchenOrderTicket: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Kitchen Order Tickets for multiple kitchens",
      content: [
        {
          para: "You can generate Kitchen Order Tickets in multiple kitchens based on the product category.",
        },
        {
          para: "To add a new order ticket group go categories ->  order ticketgroups -> Add order ticket group .",
        },
        {
          img: KitchenOrderTicket1,
        },
        {
          img: KitchenOrderTicket2,
        },
        {
          para: "To assign the order ticket group to the product categories .",
        },
        {
          para: "categories -> product categories -> click on the product category -> order ticket groups .",
        },
        {
          img: KitchenOrderTicket3,
        },
        {
          para: "select the order ticket group from the list.",
        },
        {
          img: KitchenOrderTicket4,
        },
        {
          para: 'Select products from different product categories in the sell screen and click "Create order ticket".',
        },
        {
          par: "Kitchen order tickets for the respective kitchens will be generated.",
        },
        {
          img: KitchenOrderTicket5,
        },
        {
          img: KitchenOrderTicket6,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    CreateManagerCashiers: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Create managers and cashiers for access control",
      content: [
        {
          para: "Differentiate different level of users to give them the role based access and improve the usage of Posease and also avoid fraudulent activities.",
        },
        {
          para: "1. Click on Setting -> Users to add different types of users.",
        },
        {
          img: CreateManagerCashiers1,
        },
        {
          para: "2. Create Cashier/ Manager from Manager , Cashier Tab.",
        },
        {
          img: CreateManagerCashiers2,
        },
        {
          para: "3. Create All App Users from the All App user Tab.",
        },
        {
          img: CreateManagerCashiers3,
        },
        {
          img: CreateManagerCashiers4,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    DownLoadPosease: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "Get the downloadable version of Posease for desktop",
      content: [
        {
          para: "If you don't want to use Posease on a browser. You can download the installable version of Posease",
        },
        {
          para: "1. Click this Buttons on Dashboard based on your system specification to download the Posease Desktop App.",
        },
        {
          img: DownLoadPosease1,
        },
        // {
        //   para: "2. Extract the Downloaded File.",
        // },
        // {
        //   img: DownLoadPosease2,
        // },
        // {
        //   para: "3. Open the Extracted file.",
        // },
        // {
        //   img: DownLoadPosease3,
        // },
        // {
        //   para: '4. Double click on "Posease Setup 0.1.0" to open the Desktop App of Posease.',
        // },
        // {
        //   img: DownLoadPosease4,
        // },
        // {
        //   para: "Note:",
        //   subpara: [
        //     {
        //       subpara:
        //         "If you are currently using an older version of the Desktop App, you can delete the older version and start using the latest version of the Desktop App.",
        //     },
        //   ],
        // },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    WaiterCustomer: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "How to enable and use Waiter/Captain App for table orders",
      content: [
        {
          para: "1. Contact Posease team and ask us to enable Waiter app for your account. Please remember Waiter app requires upgrading your Posease subscription.",
        },
        {
          img: WaiterCustomer1,
        },
        {
          para: '2. Open Posease application (from the downloaded and extracted files) and go to Setting -> Registers -> (Choose your Register). i) Make sure table numbers are populated. ii) Copy and paste the IP address of your system to the "Server IP Address For Waiter App" field as shown in the screenshot below.',
        },
        {
          img: WaiterCustomer2,
        },
        {
          para: 'If you see multiple IP addresses in "Available networks", make sure you use the IP address of the network in which both the Posease Desktop system and Waiter mobile app will be connected.',
        },
        {
          para: "3. In Posease make sure the below preferences are set for the waiter service to be enabled.",
        },
        {
          img: WaiterCustomer3,
        },
        {
          img: WaiterCustomer4,
        },
        {
          para: "4. Create a Waiter user with a PIN.",
        },
        {
          img: WaiterCustomer5,
        },
        {
          para: "5. Install the Waiter app on your phone and login with your Posease account. Use the waiter user's PIN to unlock the waiter app. Only users who are waiter can unlock the waiter app.",
        },
        {
          para: "6. Make sure the Waiter app has joined the same WiFi Network in which Posease Desktop system is running. When this happens, you will see Green Signal in the Waiter App",
        },
        {
          img: WaiterCustomer6,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    LoyaltyCrm: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "How to enable loyalty management with CRM",
      content: [
        {
          para: "Finding it difficult to manage loyalty, coupons, referral and marketing programs? We are providing you with best solutions. Start using CRM and manage things easily.",
        },
        {
          para: "1. Go to Apps to view CRM.",
        },
        {
          img: LoyaltyCrm1,
        },
        {
          para: "2. Enable the app to start using it.",
        },
        {
          img: LoyaltyCrm2,
        },
        {
          para: "3. For new users, use the sign up option available in the screen. Fill in the required details and schedule a demo from their end for more understanding.",
        },
        {
          img: LoyaltyCrm3,
        },
        {
          para: "4. To find your Bingage API Key, click on the name to view Profile information.",
        },
        {
          img: LoyaltyCrm4,
        },
        {
          para: "5. Click on Profile to know about the integration details .",
        },
        {
          img: LoyaltyCrm5,
        },
        {
          para: "6. Move to integration Setup Tab and click on copy to copy the Auth Key or the Bingage API Key.",
        },
        {
          img: LoyaltyCrm6,
        },
        {
          para: "7. Inside Posease, click on Add Integration.",
        },
        {
          img: LoyaltyCrm7,
        },
        {
          para: "8. Paste the copied text in the input field, select the required register and click on add integration to complete the process",
        },
        {
          img: LoyaltyCrm8,
        },
        {
          para: "9. In the sell screen, you can redeem the wallet cash available while making bills.",
        },
        {
          img: LoyaltyCrm9,
        },
        {
          para: " 10. Entre the OTP, confirm Redeem and click Ok to continue billing.",
        },
        {
          img: LoyaltyCrm10,
        },
        {
          img: LoyaltyCrm11,
        },
        {
          para: " 11. Proceed billing by clicking on Charge.",
        },
        {
          img: LoyaltyCrm12,
        },
      ],
      Navbar: [
        {
          menuKey: "how-to-enable-and-use-waitercaptain-app-for-table-orders",
          Navlink:
            "posease-setup/how-to-enable-and-use-waitercaptain-app-for-table-orders",
          subPageTitle: "How to enable and use Wai...",
        },
        {
          menuKey: "customize-your-pos-with-preferences",
          Navlink: "posease-setup/customize-your-pos-with-preferences",
          subPageTitle: "Customize your POS with...",
        },
        {
          menuKey: "employee-access-control-cashiermanager-mode-avoid-fraud",
          Navlink:
            "posease-setup/employee-access-control-cashiermanager-mode-avoid-fraud",
          subPageTitle: "Employee access control...",
        },
        {
          menuKey: "add-single-tax-or-multiple-taxes-gst-to-products",
          Navlink:
            "posease-setup/add-single-tax-or-multiple-taxes-gst-to-products",
          subPageTitle: "Add single tax or multiple taxe...",
        },
        {
          menuKey: "add-multiple-registersshops-in-one-accounts",
          Navlink: "posease-setup/add-multiple-registersshops-in-one-accounts",
          subPageTitle: "Add multiple registers(shops)...",
        },
        {
          menuKey: "owner-pin-logo-setup",
          Navlink: "posease-setup/owner-pin-logo-setup",
          subPageTitle: "Owner pin, Logo setup",
        },
        {
          menuKey:
            "setup-additional-charges-parcel-delivery-service-charge-etc",
          Navlink:
            "posease-setup/setup-additional-charges-parcel-delivery-service-charge-etc",
          subPageTitle: "Setup additional charges...",
        },
        {
          menuKey: "getting-started",
          Navlink: "posease-setup/getting-started",
          subPageTitle: "Getting started",
        },
        {
          menuKey: "create-custom-payment-types",
          Navlink: "posease-setup/create-custom-payment-types",
          subPageTitle: "Create custom payment types",
        },
      ],
    },
    mpAccCntrol: {
      pagetitle: "Posease setup",
      pageLink: "posease-setup",
      subPageTitle: "",
      content: [],
      Navbar: [],
    },
  },
  printing: {
    Navbar: [
      {
        menuKey: "add-save-and-remove-printers-desktop-app",
        Navlink: "printing/add-save-and-remove-printers-desktop-app",
        subPageTitle: "Add, save and remove printers..",
      },
      {
        menuKey: "select-receipt-size-printer-type",
        Navlink: "printing/select-receipt-size-printer-type",
        subPageTitle: "Select receipt size (printer type)",
      },
      {
        menuKey: "static-ip-address-for-printers",
        Navlink: "printing/static-ip-address-for-printers",
        subPageTitle: "Static IP address for printers",
      },
      {
        menuKey: "static-ip-address-for-mac",
        Navlink: "printing/static-ip-address-for-mac",
        subPageTitle: "Static IP address for mac",
      },
    ],
    addSavePrinter: {
      pagetitle: "printing",
      pageLink: "printing",
      subPageTitle: "Add, save and remove printers (Desktop app)",
      content: [
        {
          para: "Add and save the printers in desktop app, so that you need not make the printer setup every time (Network and Silent Print). Print KOT directly in your kitchen using Network Printer.",
        },
        {
          para: "1. Select Printers from setup and click on Add Printer.",
        },
        {
          img: addSavePrinter1,
        },
        {
          img: addSavePrinter2,
        },
        {
          para: "2. Select the printer type, enter its configurations and save.",
          subpara: [
            {
              subpara: "Network Printer.",
            },
          ],
        },
        {
          img: addSavePrinter3,
        },
        {
          img: addSavePrinter4,
        },
        {
          para: "Bar printer (USB printer).",
        },
        {
          img: addSavePrinter4,
        },
        {
          para: "3. The saved printers are listed. Click on Setup to make the printer setup to print the receipt and KOT.",
        },
        {
          img: addSavePrinter5,
        },
        {
          para: "4. Select a printer to print receipt and KOT from the saved list.",
        },
        {
          img: addSavePrinter6,
        },
        {
          para: "5. To remove the added printer. Select the added printers. Click on Actions, select Remove selected printers and Delete.",
        },
        {
          img: addSavePrinter7,
        },
        {
          img: addSavePrinter8,
        },
      ],
      Navbar: [
        {
          menuKey: "add-save-and-remove-printers-desktop-app",
          Navlink: "printing/add-save-and-remove-printers-desktop-app",
          subPageTitle: "Add, save and remove printers..",
        },
        {
          menuKey: "select-receipt-size-printer-type",
          Navlink: "printing/select-receipt-size-printer-type",
          subPageTitle: "Select receipt size (printer type)",
        },
        {
          menuKey: "avoid-seeing-print-preview",
          Navlink: "printing/avoid-seeing-print-preview",
          subPageTitle: "Avoid seeing print preview",
        },
        {
          menuKey: "static-ip-address-for-printers",
          Navlink: "printing/static-ip-address-for-printers",
          subPageTitle: "Static IP address for printers",
        },
        {
          menuKey: "static-ip-address-for-mac",
          Navlink: "printing/static-ip-address-for-mac",
          subPageTitle: "Static IP address for mac",
        },
      ],
    },
    SelectReceiptSize: {
      pagetitle: "Printing",
      pageLink: "printing",
      subPageTitle: "Select receipt size (printer type)",
      content: [
        {
          para: "Choose your receipt size depending on printer",
        },
        {
          para: "1. Click on setup on left bottom of the screen.",
        },
        {
          para: '2. Select "Registers".',
        },
        {
          para: "3. Select register for which receipt size needs to be updated.",
        },
        {
          para: "4. Select the receipt print size",
        },
        {
          img: SelectReceiptSize,
        },
      ],
      Navbar: [
        {
          menuKey: "add-save-and-remove-printers-desktop-app",
          Navlink: "printing/add-save-and-remove-printers-desktop-app",
          subPageTitle: "Add, save and remove printers..",
        },
        {
          menuKey: "select-receipt-size-printer-type",
          Navlink: "printing/select-receipt-size-printer-type",
          subPageTitle: "Select receipt size (printer type)",
        },
        {
          menuKey: "avoid-seeing-print-preview",
          Navlink: "printing/avoid-seeing-print-preview",
          subPageTitle: "Avoid seeing print preview",
        },
        {
          menuKey: "static-ip-address-for-printers",
          Navlink: "printing/static-ip-address-for-printers",
          subPageTitle: "Static IP address for printers",
        },
        {
          menuKey: "static-ip-address-for-mac",
          Navlink: "printing/static-ip-address-for-mac",
          subPageTitle: "Static IP address for mac",
        },
      ],
    },
    IpAddressPri: {
      pagetitle: "Printing",
      pageLink: "printing",
      subPageTitle: "Static IP address for printers",
      content: [
        {
          para: "Steps to set statics ip for your printers are as follows:-",
        },
        {
          para: "Click Start Menu > Control Panel > Network and Sharing Center or Network and Internet > Network and Sharing Center.",
        },
        {
          img: IpAddressPri1,
        },
        {
          para: "Click Change adapter settings.",
        },
        {
          img: IpAddressPri2,
        },
        {
          para: " ",
          subpara: [
            {
              subpara:
                "Right-click on Wi-Fi or Local Area Connection and Click Properties.",
            },
            {
              subpara:
                "Select Internet Protocol Version 4 (TCP/IPv4) and Click Properties.",
            },
          ],
        },
        {
          img: IpAddressPri3,
        },
        {
          para: "Select Use the following IP address.",
        },
        {
          para: "Enter the IP address, Subnet mask, Default gateway, and DNS server.",
        },
        {
          para: "Obtain the default gateway from the command prompt by using command (ipconfig)",
        },
        {
          para: "Click OK.",
        },
        {
          img: IpAddressPri4,
        },
        {
          img: IpAddressPri5,
        },
        {
          para: "Now open the Posease window",
        },
        {
          para: "Click on setting on Profile Icon and open the register in which you want to set static IP address..",
        },
        {
          img: IpAddressMac5,
        },
        {
          para: "Enter the static IP address now and save it.",
        },
        {
          img: IpAddressMac6,
        },
      ],
      Navbar: [
        {
          menuKey: "add-save-and-remove-printers-desktop-app",
          Navlink: "printing/add-save-and-remove-printers-desktop-app",
          subPageTitle: "Add, save and remove printers..",
        },
        {
          menuKey: "select-receipt-size-printer-type",
          Navlink: "printing/select-receipt-size-printer-type",
          subPageTitle: "Select receipt size (printer type)",
        },
        {
          menuKey: "avoid-seeing-print-preview",
          Navlink: "printing/avoid-seeing-print-preview",
          subPageTitle: "Avoid seeing print preview",
        },
        {
          menuKey: "static-ip-address-for-printers",
          Navlink: "printing/static-ip-address-for-printers",
          subPageTitle: "Static IP address for printers",
        },
        {
          menuKey: "static-ip-address-for-mac",
          Navlink: "printing/static-ip-address-for-mac",
          subPageTitle: "Static IP address for mac",
        },
      ],
    },
    IpAddressMac: {
      pagetitle: "Printing",
      pageLink: "printing",
      subPageTitle: "Static IP address for mac",
      content: [
        {
          para: "To set static IP address for Mac (iOS) follow the steps:-",
        },
        {
          para: "1. Go to settings and click on network option",
        },
        {
          img: IpAddressMac1,
        },
        {
          para: "2. Choose your network connection by clicking on network name, then click on advanced option.",
        },
        {
          img: IpAddressMac2,
        },
        {
          para: "3. Select TCP/IP, Click on drop down list of Configure ip4 and select manually option.",
        },
        {
          para: "4. Enter the IP address for example: 192. 168. 1 .130",
        },
        {
          img: IpAddressMac3,
        },
        {
          para: "5. Select the DNS option next to TCP/IP and enter the DNS address which you have and apply changes.",
        },
        {
          img: IpAddressMac4,
        },
        {
          para: "Now open the Posease window",
        },
        {
          para: "Click on setting on Profile Icon and open the register in which you want to set static IP address..",
        },
        {
          img: IpAddressMac5,
        },
        {
          para: "Enter the static IP address now and save it.",
        },
        {
          img: IpAddressMac6,
        },
      ],
      Navbar: [
        {
          menuKey: "add-save-and-remove-printers-desktop-app",
          Navlink: "printing/add-save-and-remove-printers-desktop-app",
          subPageTitle: "Add, save and remove printers..",
        },
        {
          menuKey: "select-receipt-size-printer-type",
          Navlink: "printing/select-receipt-size-printer-type",
          subPageTitle: "Select receipt size (printer type)",
        },
        {
          menuKey: "avoid-seeing-print-preview",
          Navlink: "printing/avoid-seeing-print-preview",
          subPageTitle: "Avoid seeing print preview",
        },
        {
          menuKey: "static-ip-address-for-printers",
          Navlink: "printing/static-ip-address-for-printers",
          subPageTitle: "Static IP address for printers",
        },
        {
          menuKey: "static-ip-address-for-mac",
          Navlink: "printing/static-ip-address-for-mac",
          subPageTitle: "Static IP address for mac",
        },
      ],
    },
    avoidPreview: {
      pagetitle: "Printing",
      pageLink: "printing",
      subPageTitle: "Avoid seeing print preview",
      content: [
        {
          para: "Windows:",
        },
        {
          para: "1. Check that your receipt printer is set as your default printer.",
        },
        {
          para: "2. Create a Google Chrome shortcut on your desktop, rename it to like Poseaase to make unique.",
        },
        {
          para: '3. Right click on the new shortcut on your Desktop and select " Properties", and click on " Shortcut" TAB.',
        },
        {
          para: "4. At the end of the target field add:",
        },
        {
          para: "--kiosk-printing and click OK.",
        },
        {
          img: avoidPreview1,
        },
        {
          para: "You are done !",
        },
        {
          para: "Mac",
        },
        {
          para: "1. Quit your chrome browser",
        },
        {
          para: "2. Open terminal",
        },
        {
          para: "3. Execute command - bash",
        },
        {
          para: "4. Execute command - /Applications/Google Chrome.app/Contents/MacOS/Google Chrome -kiosk-printing --prevent-review web.Poseaase.com",
        },
        {
          para: "You are done !",
        },
      ],
      Navbar: [
        {
          menuKey: "add-save-and-remove-printers-desktop-app",
          Navlink: "printing/add-save-and-remove-printers-desktop-app",
          subPageTitle: "Add, save and remove printers..",
        },
        {
          menuKey: "select-receipt-size-printer-type",
          Navlink: "printing/select-receipt-size-printer-type",
          subPageTitle: "Select receipt size (printer type)",
        },
        {
          menuKey: "avoid-seeing-print-preview",
          Navlink: "printing/avoid-seeing-print-preview",
          subPageTitle: "Avoid seeing print preview",
        },
        {
          menuKey: "static-ip-address-for-printers",
          Navlink: "printing/static-ip-address-for-printers",
          subPageTitle: "Static IP address for printers",
        },
        {
          menuKey: "static-ip-address-for-mac",
          Navlink: "printing/static-ip-address-for-mac",
          subPageTitle: "Static IP address for mac",
        },
      ],
    },
    subCategories: {
      pagetitle: "Printing",
      pageLink: "printing",
      subPageTitle: "",
      content: [],
      Navbar: [],
    },
  },
  customerDetails: {
    Navbar: [
      {
        menuKey: "view-customer-details-and-export",
        Navlink: "customer-details/view-customer-details-and-export",
        subPageTitle: "View customer details and export",
      },
      {
        menuKey: "import-customer-data",
        Navlink: "customer-details/import-customer-data",
        subPageTitle: "Import customer data",
      },
    ],
    exportCustomerDetail: {
      pagetitle: "Customer Details",
      pageLink: "customer-details",
      subPageTitle: "View customer details and export",
      content: [
        {
          para: "Knowing your customer is key to any businesses success. In Posease view the customer detail along with their order count, total value and the last time they shopped with you.",
        },
        {
          img: exportCustomerDetail1,
        },
        {
          para: "While exporting customers the receiving email can be given where the .csv files is to be sent.",
        },
        {
          img: exportCustomerDetail2,
        },
      ],
      Navbar: [
        {
          menuKey: "view-customer-details-and-export",
          Navlink: "customer-details/view-customer-details-and-export",
          subPageTitle: "View customer details and export",
        },
        {
          menuKey: "import-customer-data",
          Navlink: "customer-details/import-customer-data",
          subPageTitle: "Import customer data",
        },
      ],
    },
    importCustomerDetail: {
      pagetitle: "Customer Details",
      pageLink: "customer-details",
      subPageTitle: "Import customer data",
      content: [
        {
          para: "Go ahead to import customer data in one shot",
        },
        {
          para: "1. Select customers from the menu. Then click on import.*",
        },
        {
          img: importCustomerDetail1,
        },
        {
          para: "2. Download the template for customer data import.",
        },
        {
          img: importCustomerDetail2,
        },
      ],
      Navbar: [
        {
          menuKey: "view-customer-details-and-export",
          Navlink: "customer-details/view-customer-details-and-export",
          subPageTitle: "View customer details and export",
        },
        {
          menuKey: "import-customer-data",
          Navlink: "customer-details/import-customer-data",
          subPageTitle: "Import customer data",
        },
      ],
    },
    subCategories: {
      pagetitle: "",
      pageLink: "",
      subPageTitle: "",
      content: [],
      Navbar: [],
    },
  },
  reporting: {
    Navbar: [
      {
        menuKey: "export-reports",
        Navlink: "reporting/export-reports",
        subPageTitle: "Export reports",
      },
      {
        menuKey: "kot-report-view-completed-and-cancelled-kots-avoid-fraud",
        Navlink:
          "reporting/kot-report-view-completed-and-cancelled-kots-avoid-fraud",
        subPageTitle: "KOT Report - View completed...",
      },
      {
        menuKey: "shift-openclose-report",
        Navlink: "reporting/shift-openclose-report",
        subPageTitle: "Shift open/close report",
      },
      {
        menuKey: "metrics-in-dashboard",
        Navlink: "reporting/metrics-in-dashboard",
        subPageTitle: "Metrics in Dashboard",
      },
    ],
    MetricsDashboard: {
      pagetitle: "Reporting",
      pageLink: "reporting",
      subPageTitle: "Metrics in Dashboard",
      content: [
        {
          para: "You can get a quick update on the status of the sales on a particular day or month. Select by register if you have multiple registers. The information available on the dashboard are",
          subpara: [
            {
              subpara: "Total sales",
            },
            {
              subpara: "Total bills",
            },
            {
              subpara: "New customers",
            },
            {
              subpara: "Top selling product",
            },
          ],
        },
        {
          img: MetricsDashboard1,
        },
      ],
      Navbar: [
        {
          menuKey: "export-reports",
          Navlink: "reporting/export-reports",
          subPageTitle: "Export reports",
        },
        {
          menuKey: "kot-report-view-completed-and-cancelled-kots-avoid-fraud",
          Navlink:
            "reporting/kot-report-view-completed-and-cancelled-kots-avoid-fraud",
          subPageTitle: "KOT Report - View completed...",
        },
        {
          menuKey: "shift-openclose-report",
          Navlink: "reporting/shift-openclose-report",
          subPageTitle: "Shift open/close report",
        },
        {
          menuKey: "metrics-in-dashboard",
          Navlink: "reporting/metrics-in-dashboard",
          subPageTitle: "Metrics in Dashboard",
        },
      ],
    },
    exportReport: {
      pagetitle: "Reporting",
      pageLink: "reporting",
      subPageTitle: "Export reports",
      content: [
        {
          para: "Get the below reports and track your business. The reports are sent to your preferred email address.",
          subpara: [
            {
              subpara: "Sales",
            },
            {
              subpara: "Payment",
            },
            {
              subpara: "Daily sales and payment",
            },
            {
              subpara: "Product-wise sales",
            },
          ],
        },
        {
          para: "To download the reports follow these steps.",
        },
        {
          para: "1. Click receipts on side bar.",
        },
        {
          para: "2. Click on Export and select the date range of report.",
        },
        {
          img: exportReport1,
        },
        {
          para: "3. The report will be delivered to the requested email ID in a few minutes.",
        },
        {
          img: exportReport2,
        },
      ],

      Navbar: [
        {
          menuKey: "export-reports",
          Navlink: "reporting/export-reports",
          subPageTitle: "Export reports",
        },
        {
          menuKey: "kot-report-view-completed-and-cancelled-kots-avoid-fraud",
          Navlink:
            "reporting/kot-report-view-completed-and-cancelled-kots-avoid-fraud",
          subPageTitle: "KOT Report - View completed...",
        },
        {
          menuKey: "shift-openclose-report",
          Navlink: "reporting/shift-openclose-report",
          subPageTitle: "Shift open/close report",
        },
        {
          menuKey: "metrics-in-dashboard",
          Navlink: "reporting/metrics-in-dashboard",
          subPageTitle: "Metrics in Dashboard",
        },
      ],
    },
    ViewCompletedCancelledReport: {
      pagetitle: "Reporting",
      pageLink: "reporting",
      subPageTitle:
        "KOT Report - View completed & cancelled KOTs (Avoid fraud)",
      content: [
        {
          para: "It is important to assure the items in the KOT and the sales match. Also the cancelled KOT's have to be tracked to avoid fraud.",
        },
        {
          para: "Completed and cancelled Kitchen Order Tickets can be viewed for a particular receipt or for several receipts by exporting the Order Tickets Report.",
        },
        {
          para: "To access the cancelled kitchen order ticket of a particular receipt,",
        },
        {
          para: "1. Click Receipts",
        },
        {
          para: "2. select a receipt",
        },
        {
          para: "3. Click on the Order Ticket number to view the cancelled items in that kitchen.",
        },
        {
          img: ViewCompletedCancelledReport1,
        },
        {
          img: ViewCompletedCancelledReport2,
        },
        {
          para: "To access cancelled Kitchen Order Tickets for multiple receipts,",
        },
        {
          para: "1. Click Export",
        },
        {
          para: "2. Select the Report type as Order Ticket Report.",
        },
        {
          para: "3. Select the time period for the report.",
        },
        {
          para: "4. Select the shop for which the report is generated.",
        },
        {
          para: "5. Enter the email Id.",
        },
        {
          img: ViewCompletedCancelledReport3,
        },
        {
          img: ViewCompletedCancelledReport4,
        },
        {
          para: "6. The Order ticket Report will be sent to the specified email Id.",
        },
        {
          img: ViewCompletedCancelledReport5,
        },
        {
          para: "7. Download the attachment and extract it to view the cancelled KOTs.",
        },
        {
          img: ViewCompletedCancelledReport6,
        },
        {
          para: "Item name denotes the item for which order ticket is generated or cancelled.",
        },
        {
          para: "Item quantity denotes the quantity of the item which is added or cancelled from the receipt.",
        },
        {
          para: "A Positive value indicates that an item is added in the receipt after an order ticket is generated and a negative value indicates that an item is removed from the receipt after an order ticket is generated",
        },
      ],
      Navbar: [
        {
          menuKey: "export-reports",
          Navlink: "reporting/export-reports",
          subPageTitle: "Export reports",
        },
        {
          menuKey: "kot-report-view-completed-and-cancelled-kots-avoid-fraud",
          Navlink:
            "reporting/kot-report-view-completed-and-cancelled-kots-avoid-fraud",
          subPageTitle: "KOT Report - View completed...",
        },
        {
          menuKey: "shift-openclose-report",
          Navlink: "reporting/shift-openclose-report",
          subPageTitle: "Shift open/close report",
        },
        {
          menuKey: "metrics-in-dashboard",
          Navlink: "reporting/metrics-in-dashboard",
          subPageTitle: "Metrics in Dashboard",
        },
      ],
    },
    ShiftOpenCloseReport: {
      pagetitle: "Reporting",
      pageLink: "reporting",
      subPageTitle: "Shift open/close report",
      content: [
        {
          para: "You can get a report of all your shifts with the shift open/close report.",
        },
        {
          para: "1. Go to receipts tab and click on export.",
        },
        {
          img: ShiftOpenCloseReport1,
        },
        {
          para: "2. Select Shift open/close report type and export the report to your email ID.",
        },
        {
          img: ShiftOpenCloseReport2,
        },
      ],
      Navbar: [
        {
          menuKey: "export-reports",
          Navlink: "reporting/export-reports",
          subPageTitle: "Export reports",
        },
        {
          menuKey: "kot-report-view-completed-and-cancelled-kots-avoid-fraud",
          Navlink:
            "reporting/kot-report-view-completed-and-cancelled-kots-avoid-fraud",
          subPageTitle: "KOT Report - View completed...",
        },
        {
          menuKey: "shift-openclose-report",
          Navlink: "reporting/shift-openclose-report",
          subPageTitle: "Shift open/close report",
        },
        {
          menuKey: "metrics-in-dashboard",
          Navlink: "reporting/metrics-in-dashboard",
          subPageTitle: "Metrics in Dashboard",
        },
      ],
    },
    subCategories: {
      pagetitle: "",
      pageLink: "",
      subPageTitle: "",
      content: [],
      Navbar: [],
    },
  },
  Inventory: {
    Navbar: [
      {
        menuKey: "create-ingredients-raw-material",
        Navlink: "inventory-app/create-ingredients-raw-material",
        subPageTitle: "Create Ingredients (Raw material)",
      },
      {
        menuKey: "add-inventory",
        Navlink: "inventory-app/add-inventory",
        subPageTitle: "Add inventory",
      },
      {
        menuKey: "inventory-operations",
        Navlink: "inventory-app/inventory-operations",
        subPageTitle: "Inventory operations",
      },
      {
        menuKey: "create-recipe",
        Navlink: "inventory-app/create-recipe",
        subPageTitle: "Create recipe",
      },
      {
        menuKey: "inward-a-purchased-product-or-raw-material",
        Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
        subPageTitle: "Inward a purchased product or raw material",
      },
      {
        menuKey: "manage-Wastage-in-inventory",
        Navlink: "inventory-app/manage-Wastage-in-inventory",
        subPageTitle: "Manage Wastage in inventory",
      },
      {
        menuKey: "adjustment-of-materials",
        Navlink: "inventory-app/adjustment-of-materials",
        subPageTitle: "Adjustment of materials",
      },
    ],
    CreateIngredients: {
      pagetitle: "Inventory App",
      pageLink: "inventory-app",
      subPageTitle: "Create Ingredients (Raw material)",
      content: [
        {
          para:'What is the most important factor that causes loss in the food industry? "Pilferage". Make sure your raw material consumption is monitored to avoid it.'
        },
        {
          para:"Go to the inventory app in the app store."
        },
        {
          img:CreateIngredients1
        },
        {
          para:"​Click on manage items to manage the raw materials."
        },
        {
          img:CreateIngredients2
        },
        {
          para:"​You can create and manage your recipe's here."
        },
        {
          img:CreateIngredients3
        },
        {
          img:CreateIngredients4
        },
      ],
      Navbar: [
        {
          menuKey: "create-ingredients-raw-material",
          Navlink: "inventory-app/create-ingredients-raw-material",
          subPageTitle: "Create Ingredients (Raw material)",
        },
        {
          menuKey: "add-inventory",
          Navlink: "inventory-app/add-inventory",
          subPageTitle: "Add inventory",
        },
        {
          menuKey: "inventory-operations",
          Navlink: "inventory-app/inventory-operations",
          subPageTitle: "Inventory operations",
        },
        {
          menuKey: "create-recipe",
          Navlink: "inventory-app/create-recipe",
          subPageTitle: "Create recipe",
        },
        {
          menuKey: "inward-a-purchased-product-or-raw-material",
         Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
          subPageTitle: "Inward a purchased product...",
        },
        {
          menuKey: "manage-Wastage-in-inventory",
          Navlink: "inventory-app/manage-Wastage-in-inventory",
          subPageTitle: "Manage Wastage in inventory",
        },
        {
          menuKey: "adjustment-of-materials",
          Navlink: "inventory-app/adjustment-of-materials",
          subPageTitle: "Adjustment of materials",
        },
      ],
    },
    AddInventory: {
      pagetitle: "Inventory App",
      pageLink: "inventory-app",
      subPageTitle: "Add inventory",
      content: [
        {
          para:"Managing inventory is critical in avoiding wastage and making sure you have stocks to fulfill customer needs. Be informed."
        },
        {
          para:"1. Go to apps in the menu"
        },
        {
          img:AddInventory1
        },
        {
          para:"2. Add inventory to one register or multiple registers"
        },
        {
          img:AddInventory2
        },
        {
          para:"3. Select inventory to add stock."
        },
        {
          img:AddInventory3
        },
        {
          para:"Inventory can be created for registers or multiple registers."
        },
        {
          para:"4. Add the current inventory by Inward"
        },
        {
          img:AddInventory4
        },
        {
          img:AddInventory5
        },
        {
          para:"Inventory gets updated and after each sell the inventory is reduced."
        }

      ],
      Navbar: [
        {
          menuKey: "create-ingredients-raw-material",
          Navlink: "inventory-app/create-ingredients-raw-material",
          subPageTitle: "Create Ingredients (Raw material)",
        },
        {
          menuKey: "add-inventory",
          Navlink: "inventory-app/add-inventory",
          subPageTitle: "Add inventory",
        },
        {
          menuKey: "inventory-operations",
          Navlink: "inventory-app/inventory-operations",
          subPageTitle: "Inventory operations",
        },
        {
          menuKey: "create-recipe",
          Navlink: "inventory-app/create-recipe",
          subPageTitle: "Create recipe",
        },
        {
          menuKey: "inward-a-purchased-product-or-raw-material",
         Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
          subPageTitle: "Inward a purchased product...",
        },
        {
          menuKey: "manage-Wastage-in-inventory",
          Navlink: "inventory-app/manage-Wastage-in-inventory",
          subPageTitle: "Manage Wastage in inventory",
        },
        {
          menuKey: "adjustment-of-materials",
          Navlink: "inventory-app/adjustment-of-materials",
          subPageTitle: "Adjustment of materials",
        },
      ],
    },
    InventoryOperations: {
      pagetitle: "Inventory App",
      pageLink: "inventory-app",
      subPageTitle: "Inventory operations",
      content: [
        {
          para:"Select the option depending on your requirement and update your inventory."
        },
        {
          img:InventoryOperations
        },
      ],
      Navbar: [
        {
          menuKey: "create-ingredients-raw-material",
          Navlink: "inventory-app/create-ingredients-raw-material",
          subPageTitle: "Create Ingredients (Raw material)",
        },
        {
          menuKey: "add-inventory",
          Navlink: "inventory-app/add-inventory",
          subPageTitle: "Add inventory",
        },
        {
          menuKey: "inventory-operations",
          Navlink: "inventory-app/inventory-operations",
          subPageTitle: "Inventory operations",
        },
        {
          menuKey: "create-recipe",
          Navlink: "inventory-app/create-recipe",
          subPageTitle: "Create recipe",
        },
        {
          menuKey: "inward-a-purchased-product-or-raw-material",
         Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
          subPageTitle: "Inward a purchased product...",
        },
        {
          menuKey: "manage-Wastage-in-inventory",
          Navlink: "inventory-app/manage-Wastage-in-inventory",
          subPageTitle: "Manage Wastage in inventory",
        },
        {
          menuKey: "adjustment-of-materials",
          Navlink: "inventory-app/adjustment-of-materials",
          subPageTitle: "Adjustment of materials",
        },
      ],
    },
    CreateRecipe: {
      pagetitle: "Inventory App",
      pageLink: "inventory-app",
      subPageTitle: "Create recipe",
      content: [
        {
          para:'Enter the recipe that make up your product.'
        },
        {
          para:'Go to manage items in the inventory. Then edit the product that you want to create the recipe for.'
        },
        {
          img:CreateRecipe1
        },
        {
          para:'Enter the recipe and the corresponding quantities.'
        },
        {
          img:CreateRecipe2
        },
      ],
      Navbar: [
        {
          menuKey: "create-ingredients-raw-material",
          Navlink: "inventory-app/create-ingredients-raw-material",
          subPageTitle: "Create Ingredients (Raw material)",
        },
        {
          menuKey: "add-inventory",
          Navlink: "inventory-app/add-inventory",
          subPageTitle: "Add inventory",
        },
        {
          menuKey: "inventory-operations",
          Navlink: "inventory-app/inventory-operations",
          subPageTitle: "Inventory operations",
        },
        {
          menuKey: "create-recipe",
          Navlink: "inventory-app/create-recipe",
          subPageTitle: "Create recipe",
        },
        {
          menuKey: "inward-a-purchased-product-or-raw-material",
          Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
          subPageTitle: "Inward a purchased product...",
        },
        {
          menuKey: "manage-Wastage-in-inventory",
          Navlink: "inventory-app/manage-Wastage-in-inventory",
          subPageTitle: "Manage Wastage in inventory",
        },
        {
          menuKey: "adjustment-of-materials",
          Navlink: "inventory-app/adjustment-of-materials",
          subPageTitle: "Adjustment of materials",
        },
      ],
    },
    Inward: {
      pagetitle: "Inventory App",
      pageLink: "inventory-app",
      subPageTitle: "Inward a purchased product or raw material",
      content: [
        {
          para:"To Inward a purchased product or raw material."
        },
        {
          para:"Firstly, choose the desired inventory from the inventory app to inward the purchased product."
        },
        {
          img:Inward2
        },
        {
          para:"Choose inward option and enter the details of the purchased product."
        },
        {
          img:Inward3
        },
        {
          para:"Finally save it."
        },
        {
          img:Inward4
        },
        
      ],
      Navbar: [
        {
          menuKey: "create-ingredients-raw-material",
          Navlink: "inventory-app/create-ingredients-raw-material",
          subPageTitle: "Create Ingredients (Raw material)",
        },
        {
          menuKey: "add-inventory",
          Navlink: "inventory-app/add-inventory",
          subPageTitle: "Add inventory",
        },
        {
          menuKey: "inventory-operations",
          Navlink: "inventory-app/inventory-operations",
          subPageTitle: "Inventory operations",
        },
        {
          menuKey: "create-recipe",
          Navlink: "inventory-app/create-recipe",
          subPageTitle: "Create recipe",
        },
        {
          menuKey: "inward-a-purchased-product-or-raw-material",
         Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
          subPageTitle: "Inward a purchased product...",
        },
        {
          menuKey: "manage-Wastage-in-inventory",
          Navlink: "inventory-app/manage-Wastage-in-inventory",
          subPageTitle: "Manage Wastage in inventory",
        },
        {
          menuKey: "adjustment-of-materials",
          Navlink: "inventory-app/adjustment-of-materials",
          subPageTitle: "Adjustment of materials",
        },
      ],
    },
    ManageWastage: {
      pagetitle: "Inventory App",
      pageLink: "inventory-app",
      subPageTitle: "Manage Wastage in inventory",
      content: [
        {
          para:"To avoid losses, it is a good practice to follow the wastage of your inventory."
        },
        {
          para:"There by, You can monitor your left outs in your inventory by the following steps :-"
        },
        {
          para:"Select the inventory option from the apps menu."
        },
        {
          img:ManageWastage1
        },
        {
          para:"Select the desired inventory from which you want to look up on the wastage."
        },
        {
          img:ManageWastage2
        },
        {
          para:"Select the wastage option and add the item details."
        },
        {
          para:"You can add multiple items by clicking on add more option and import the products when you have bulk items and save it."
        },
        {
          img:ManageWastage3
        },
        {
          img:ManageWastage4
        },
        {
          para:"NOTE :- You have to download the CSV template and import the bulk wasted items."
        },
      ],
      Navbar: [
        {
          menuKey: "create-ingredients-raw-material",
          Navlink: "inventory-app/create-ingredients-raw-material",
          subPageTitle: "Create Ingredients (Raw material)",
        },
        {
          menuKey: "add-inventory",
          Navlink: "inventory-app/add-inventory",
          subPageTitle: "Add inventory",
        },
        {
          menuKey: "inventory-operations",
          Navlink: "inventory-app/inventory-operations",
          subPageTitle: "Inventory operations",
        },
        {
          menuKey: "create-recipe",
          Navlink: "inventory-app/create-recipe",
          subPageTitle: "Create recipe",
        },
        {
          menuKey: "inward-a-purchased-product-or-raw-material",
         Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
          subPageTitle: "Inward a purchased product...",
        },
        {
          menuKey: "manage-Wastage-in-inventory",
          Navlink: "inventory-app/manage-Wastage-in-inventory",
          subPageTitle: "Manage Wastage in inventory",
        },
        {
          menuKey: "adjustment-of-materials",
          Navlink: "inventory-app/adjustment-of-materials",
          subPageTitle: "Adjustment of materials",
        },
      ],
    },
    AdjustmentMaterials: {
      pagetitle: "Inventory App",
      pageLink: "inventory-app",
      subPageTitle: "Adjustment of materials",
      content: [
      {
        para:'In Posease we provide you the feature of adding and editing the quantity of the items through, "ADJUSTMENT" option'
      },
      {
        para:'In this case we can maintain Our inventory avoiding and detecting pilferage.'
      },
      {
        para:'Select the Inventory option from the apps menu.'
      },
      {
        img:AdjustmentMaterials1
      },
      {
        para:'Choose the desired inventory from which you want to adjust your items.'
      },
      {
        para:'Click on the adjustment option to modify the quantity of the items.'
      },
      {
        img:AdjustmentMaterials2
      },
      {
        img:AdjustmentMaterials3
      },
      {
        para:'Enter the item name and quantity of the item. Then save it.'
      },
      {
        para:'You can add more items by clicking on the add more option and click on import option to import bulk items'
      },
      {
        img:AdjustmentMaterials4
      },
      {
        para:"NOTE :- You have to download the csv template and upload it in this format."
      }
      ],
      Navbar: [
        {
          menuKey: "create-ingredients-raw-material",
          Navlink: "inventory-app/create-ingredients-raw-material",
          subPageTitle: "Create Ingredients (Raw material)",
        },
        {
          menuKey: "add-inventory",
          Navlink: "inventory-app/add-inventory",
          subPageTitle: "Add inventory",
        },
        {
          menuKey: "inventory-operations",
          Navlink: "inventory-app/inventory-operations",
          subPageTitle: "Inventory operations",
        },
        {
          menuKey: "create-recipe",
          Navlink: "inventory-app/create-recipe",
          subPageTitle: "Create recipe",
        },
        {
          menuKey: "inward-a-purchased-product-or-raw-material",
         Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
          subPageTitle: "Inward a purchased product...",
        },
        {
          menuKey: "manage-Wastage-in-inventory",
          Navlink: "inventory-app/manage-Wastage-in-inventory",
          subPageTitle: "Manage Wastage in inventory",
        },
        {
          menuKey: "adjustment-of-materials",
          Navlink: "inventory-app/adjustment-of-materials",
          subPageTitle: "Adjustment of materials",
        },
      ],
      subCategories: {
        pagetitle: "Inventory App",
        pageLink: "inventory-app",
        subPageTitle: "",
        content: [],
        Navbar: [
          {
            menuKey: "create-ingredients-raw-material",
            Navlink: "inventory-app/create-ingredients-raw-material",
            subPageTitle: "Create Ingredients (Raw material)",
          },
          {
            menuKey: "add-inventory",
            Navlink: "inventory-app/add-inventory",
            subPageTitle: "Add inventory",
          },
          {
            menuKey: "inventory-operations",
            Navlink: "inventory-app/inventory-operations",
            subPageTitle: "Inventory operations",
          },
          {
            menuKey: "create-recipe",
            Navlink: "inventory-app/create-recipe",
            subPageTitle: "Create recipe",
          },
          {
            menuKey: "inward-a-purchased-product-or-raw-material",
            Navlink: "inventory-app/inward-a-purchased-product-or-raw-material",
            subPageTitle: "Inward a purchased product or raw material",
          },
          {
            menuKey: "manage-Wastage-in-inventory",
            Navlink: "inventory-app/manage-Wastage-in-inventory",
            subPageTitle: "Manage Wastage in inventory",
          },
          {
            menuKey: "adjustment-of-materials",
            Navlink: "inventory-app/adjustment-of-materials",
            subPageTitle: "Adjustment of materials",
          },
        ],
      },
    },
  },
};
