import React from "react";

import {
  BrowserRouter as Router,
} from "react-router-dom";
import HFLayout from "./Layout/HFLayout";
import IndexRoutes from "./Routes/IndexRoutes";

const App = () => {
  return (
    <Router basename="/userguide">
      <HFLayout>
        <IndexRoutes />
      </HFLayout>
    </Router>
  );
};

export default App;
