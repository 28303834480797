import { Card} from "antd";
import React, { useState, useEffect } from "react";
import "./CardItem.css";
import icon from "../img/mainicon.svg";
import { useNavigate } from "react-router";
import Navbar from "./Navbar";
import useDocumentTitle from "../useDocumentTitle";
const CardItem = (props) => {
  // console.log(props);
  const history = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  {
    useDocumentTitle(props.prop[0].pageTitle);
  }

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth >= 980 ? <Navbar /> : ""}
      <div>
        {Object.entries(props.prop).map(([key, value]) => {
          return (
            <Card
              style={{
                marginBottom: "15px",
                marginLeft: "15px",
                marginRight: "15px",
              }}
              bodyStyle={{ padding: "20px 40px" }}
              key={key}
              onClick={() => {
                if (value.path) {
                  history(`/${value.path}`);
                }
              }}
            >
              <>
                <div className="card_title">
                  <h3
                    style={{
                      margin: 0,
                      fontSize: "17.1px",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      src={icon}
                      alt=""
                      style={{
                        filter:
                          "invert(9%) sepia(74%) saturate(6917%) hue-rotate(323deg) brightness(100%) contrast(100%)",
                        paddingRight: "10px",
                      }}
                    />
                    {value.title}
                  </h3>
                </div>
                <div
                 
                  className="card_text"
                >
                  <p
                    style={{
                      fontSize: "13px",
                      textOverflow: "ellipsis",
                      // textAlign: "justify",
                    }}
                  >
                    {value.text.slice(0, 145)}...
                  </p>
                  {/* <Divider type="vertical" /> */}
                </div>
              </>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default CardItem;
