import React, { useState, useEffect, Fragment } from "react";
import { StarFilled, RightOutlined } from "@ant-design/icons";
import Card from "antd/es/card/Card";
import "./CategoriesList.css";
import { Row, Col, } from "antd";
import { FrequentlyReadArticles, allCategories } from "./categoriesListobj";
import { useNavigate, useLocation } from "react-router-dom";
import useDocumentTitle from "./useDocumentTitle";
const CategoriesList = () => {
  // const { path } = useRouteMatch();
  let history = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  // const Categories = [{}];
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [location]);
  useDocumentTitle("Posease User Guide");

  return (
    <div>
      <div style={{ margin: "auto 15px" }}>
        <div>
          <div className="question_title" style={{ justifyContent: "center" }}>
            <StarFilled
              style={{
                color: "#bd025d",
                letterSpacing: "0!important",
                marginRight: "5px",
              }}
            />
            Frequently Read Articles
          </div>
        </div>
        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          {Object.entries(FrequentlyReadArticles).map(([key, value]) => {
            return (
              <Fragment key={key}>
                <Col xs={24} sm={12}>
                  <Card
                    onClick={() => {
                      return value.path ? history(`/${value.path}`) : null;
                    }}
                    bodyStyle={{
                      padding: "18px 22px 19px 24px",
                      boxShadow: " 0 2px 4px 0 rgb(209 19 19 / 4%)",
                    }}
                  >
                    <Row>
                      <Col xs={23} sm={23}>
                        <p>{value.p}</p>
                      </Col>
                      <Col xs={1} sm={1}>
                        <RightOutlined />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Fragment>
            );
          })}
        </Row>
      </div>
      <h1
        style={{
          marginBottom: "30px",
          marginTop: "60px",
          marginLeft: "16px",
          fontSize: "20px",
          fontWeight: "800",
          letterSpacing: 0,
          textAlign: windowWidth >= 980 ? "initial" : "center",
        }}
      >
        Browse All Categories
      </h1>
      <Row gutter={[16, 16]} className="row_dis">
        {Object.entries(allCategories).map(([key, value]) => {
          return (
            <Fragment key={key}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Card
                  onClick={() => {
                    history(`/${value.path}`);
                  }}
                  bodyStyle={{ padding: "12px 24px" }}
                >
                  <div className="card_wrapper">
                    <div className="card_img">
                      <img src={value.logo} alt="image"/>
                    </div>
                    <div className="card_text">
                      <button className="Categories_btn">{value.title}</button>

                      <p>{value.text}</p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Fragment>
          );
        })}
      </Row>
    </div>
  );
};

export default CategoriesList;
