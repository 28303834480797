import Styled from 'styled-components';

export const NavbarCSS = Styled.div`
.menu-item{
  width:fit-content;
  text-align:justify
}
.menu-item NavLink{
  width:fit-content;
  text-align:justify
}
.menu-item.ant-menu-item-selected {
  background-color: #F3F6FB;
  color: black;
  font-weight: 700;
}`;
