import Card from "antd/es/card/Card";
import React, { useState, useEffect, Fragment } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Modal, Input, Form } from "antd";
import Feedbacksvg from "./feedbackmail.svg";
import "./guideWithImg.css";
import { RightCircleOutlined } from "@ant-design/icons";
import useDocumentTitle from "../useDocumentTitle";
import UseguideWithSSNavbar from "./UseguideWithSSNavbar";
const ThirdPageFrame = (props) => {
  // console.log("ll", props.prop);

    useDocumentTitle(props.prop.subPageTitle);
  

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const history = useNavigate();
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [location]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  // const showModal = () => {
  //   form.resetFields();
  //   setVisible(true);
  // };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {windowWidth >= 980 ? <UseguideWithSSNavbar prop={props.prop} /> : ""}

      <Card
        style={{
          cursor: "default",
          width: windowWidth >= 980 ? "87%" : "100%",
          // float:"right"
        }}
      >
        <div className="guide_with_img">
          <div
            style={{
              padding: windowWidth >= 1800 ? "0 200px" : "0",
              // maxWidth: windowWidth >= 980 ? "1179px" : "initial",
            }}
          >
            <span>Article on:</span>
            <span
              style={{ color: "#bd025d", cursor: "pointer", marginLeft: "5px" }}
              onClick={() => {
                history(`/${props.prop.pageLink}`);
              }}
            >
              {props.prop.pagetitle}
            </span>
            <h1
              style={{
                color: "#1c293b",
                fontSize: "25.2px",
                fontWeight: 800,
                letterSpacing: 0,
                marginTop: 40,
              }}
            >
              {props.prop.subPageTitle}
            </h1>
            <Divider />

            {Object.entries(props.prop.content).map(([key, value]) => {
              return (
                <Fragment key={key}>
                  {value.para ? (
                    value.subpara ? (
                      <p>
                        {value.para}
                        {Object.entries(value.subpara).map(([key, value]) => {
                          return (
                            <Fragment key={key}>
                              <br />
                              <span>
                                <RightCircleOutlined
                                  style={{
                                    color: "#bd025d",
                                    marginRight: "3px",
                                  }}
                                />
                                {value.subpara}
                              </span>
                            </Fragment>
                          );
                        })}
                      </p>
                    ) : /^\d/.test(value.para) ? (
                      <>
                        <p>
                          <span
                            style={{
                              background: "#bd025d",
                              color: "white",
                              borderRadius: "50%",
                              padding: "2px 6px",
                            }}
                          >
                            {value.para[0]}
                          </span>
                          {value.para.slice(2, Infinity)}
                        </p>
                      </>
                    ) : (
                      <p>{value.para}</p>
                    )
                  ) : value.nav ? (
                    <>
                      <p
                        onClick={() => {
                          history(`/${value.nav}`);
                          console.log("ffff");
                        }}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {value.nav}
                      </p>
                    </>
                  ) : value.http ? (
                    <>
                      <a href={value.http} target="_blank">
                        {value.http}
                      </a>
                    </>
                  ) : value.img ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          marginBottom: "20px",
                          border: "1px solid #ccc",
                        }}
                        src={value.img}
                        alt="Guide with screenshort"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Fragment>
              );
            })}
            <Divider />
            {/* <div className="updateDate">
              <CalendarOutlined style={{ marginRight: 5, fontSize: "15px" }} />
              Updated on: 14/02/2023
            </div>
            <Divider /> */}
            {/* <div style={{ textAlign: "center", marginBottom: "50px" }}>
              <span className="helpful">Was this Article helpful?</span>
              <button className="yesorno" onClick={showModal}>
                Yes
              </button>
              <button className="yesorno" onClick={showModal}>
                No
              </button>
            </div> */}
            <Modal
              maskClosable={false}
              open={visible}
              onCancel={handleCancel}
              centered
              footer={[
                <Button key="submit" className="send_btn">
                  Send My Feedback
                </Button>,
                <Button
                  key="cancel"
                  onClick={handleCancel}
                  className="close_btn"
                >
                  Cancel
                </Button>,
              ]}
            >
              <span className="modal_span">
                <img src={Feedbacksvg} alt="" /> Share your feedback
              </span>
              <Form form={form}>
                <Form.Item name="text_place">
                  <Input.TextArea
                    className="text_place"
                    style={{
                      backgroundColor: "#f7f9fc",
                      border: "1px solid #dfe5eb",
                      padding: "16px 20px",
                      resize: "none",
                    }}
                    rows="6"
                    placeholder="Explain shortly what you you think about this article.&#10;We may get back to you"
                  />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ThirdPageFrame;
