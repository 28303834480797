import { Layout, Row, Col, Divider, Select } from "antd";
import React, { useState, useEffect } from "react";
import { HomeOutlined } from "@ant-design/icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import "./HFLayout.css";
import { SearchOutlined } from "@ant-design/icons";
import { MailOutlined } from "@ant-design/icons";
import { CommentOutlined } from "@ant-design/icons";
import { ObjectArray } from "../Components/objOfArticles";
import { allCategories } from "../categoriesListobj";
const { Header, Footer } = Layout;

function HFLayout(Content) {
  const openMAil = () => {
    window.location.href = "mailto:sales@posease.com";
  };
  const openChatBox = () => {
    window.$crisp.push(["do", "chat:open"]);
  };
  const history = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[2];
  useEffect(() => {
    setPath(mainPath);

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [location, mainPath]);
  // console.log("---------",location.state.key)
  const [path, setPath] = useState(`${mainPath ? mainPath : ""}`);
  const [backButtun, setBackButtun] = useState("");
  const [selectedValue, setSelectedValue] = useState(
    "Search our help center..."
  );

  const [filteredOptions, setFilteredOptions] = React.useState([]);
  useEffect(() => {
    if (pathArray.length === 2 || pathArray.length === 4) {
      setBackButtun("");
    } else if (window.location.pathname.split("/")[2] === "") {
      setBackButtun("");
    } else if (pathArray.length === 3) {
      setBackButtun(
        <div
          style={{
            padding: "10px",
            borderBottom: "1px solid #e5e9ef",
            height: "76px",
            background: "white",
          }}
        >
          <div
            style={{
              margin: windowWidth >= 980 ? "0 auto" : "0px",
              maxWidth: pathArray[3]
                ? "initial"
                : windowWidth >= 980
                ? "960px"
                : "initial",
              // margin: pathArray[2] ? "initial" : "50px auto",
            }}
          >
            <Row style={{ height: "60px", paddingTop: "16px" }}>
              <Col
                onClick={() => {
                  history("/");
                  setPath("");
                }}
                className="backbtn"
                lg={0.5}
                md={0.5}
                sm={0.5}
                xs={0.5}
              >
                <ArrowLeftOutlined />
              </Col>
              <Col
                onClick={() => {
                  history("/");
                  setBackButtun("");
                }}
                className="backbtn"
                lg={2}
                md={4}
                sm={0}
                xs={0}
              >
                <span style={{ fontSize: "15.3px", marginLeft: "8px" }}>
                  Back
                </span>
              </Col>
              {/* <Col lg={2} md={2} sm={0} xs={0}> */}
              {/* </Col> */}
              <Col
                style={{
                  marginLeft: windowWidth >= 950 ? "200px" : "20px",
                  display: "flex",
                }}
                lg={16}
                md={16}
                sm={16}
                xs={16}
              >
                <span style={{ marginRight: 10, marginTop: 6 }}>
                  Articles on:
                </span>
                <strong
                  style={{
                    padding: "7px 14px 0px 14px",
                    fontWeight: 500,
                    background: "#bd025d",
                    color: "white",
                    borderRadius: 3,
                    height: "32px",
                  }}
                >
                  {document.title}
                </strong>

                <Divider type="vertical" style={{ height: " 2.5em" }} />
                <Col className="subtitleee">
                  {windowWidth >= 950
                    ? Object.entries(allCategories).map(([key, value]) => {
                        if (document.title === value.title) {
                          return value.text;
                        } else {
                          return null;
                        }
                      })
                    : ""}
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }, [
    mainPath,
    pathArray[3],
    pathArray.length,
    path,
    windowWidth,
  ]);
  const { Option } = Select;
  const flattenDict = (input) => {
    let result = [];
    for (const value of Object.values(input)) {
      if (Array.isArray(value)) {
        result = result.concat(value.flat());
      }
    }
    return result;
  };

  const options = flattenDict(ObjectArray);
  // console.log(options);
  // const options = ObjectArray.productSetup;

  const handleSearch = (inputValue) => {
    setFilteredOptions(
      options.filter(
        (option) =>
          // console.log("hahah",option.title + " "+option.text)/
          (option.text = option.text + "," + option.title)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        // option.text.toLowerCase().split().join().includes(inputValue.toLowerCase().split().join())
      )
    );
  };

  const handleChange = (value) => {
    const selectedOption = options.find((option) => option.text === value);
    if (selectedOption && selectedOption.path) {
      history(`/${selectedOption.path}`);
      setSelectedValue(selectedOption.title);
    }
    setSelectedValue("Search our Help center...");
    setFilteredOptions([]);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSelectedValue(filteredOptions[0].title);
    }
    setSelectedValue("Search our Help center...");
    setFilteredOptions([]);
  };

  return (
    <Layout
      style={{
        display: "block",
      }}
    >
      <Header
        style={{
          width: "100%",
          top: 0,
        }}
        className="header"
      >
        <div>
          <Row
            style={{
              textAlign: windowWidth >= 980 ? "center" : "",
              justify: "",
            }}
          >
            <Col lg={12} md={12} sm={12} xs={20}>
              <span
                className="title"
                onClick={() => {
                  history("/");
                  setPath("");
                }}
              >
                Posease User Guide
              </span>
            </Col>
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={4}
              style={{
                textAlign: windowWidth >= 980 ? "" : "right",
              }}
            >
              <a href="https://www.posease.com" target="_blank">
                {windowWidth >= 580 ? (
                  <span className="weblink">Go to website</span>
                ) : (
                  <span
                    style={{ fontSize: 22, padding: "8px 15px" }}
                    className="weblink"
                  >
                    <HomeOutlined />
                  </span>
                )}
              </a>
            </Col>
          </Row>
          {windowWidth >= 980 ? (
            <Row justify="center">
              <div className="title2">How can we help you?</div>
            </Row>
          ) : (
            ""
          )}

          <div className="container_search">
            <Col
              // className="search_bar"
              style={{
                maxWidth: windowWidth >= 980 ? "40%" : "initial",
              }}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <SearchOutlined className="searchicon" />
              <Select
                value={selectedValue}
                onSearch={handleSearch}
                onChange={handleChange}
                showSearch
                className="Search"
                onKeyDown={handleKeyDown}
                notFoundContent="Sorry, no search results were found. Try with another keyword."
              >
                {/* {console.log(filteredOptions)} */}
                {filteredOptions && filteredOptions.length > 0 ? (
                  filteredOptions.map((option) => (
                    <Option
                      key={option.title}
                      value={option.text}
                      style={{
                        margin: "auto 20px",
                        marginBottom: "10px",
                        // textAlign: "center",
                      }}
                    >
                      <strong>{option.title}</strong>
                      <br />
                      {option.text.slice(0, 100)}
                      <br />
                      {option.text.slice(100, 145)}...
                    </Option>
                  ))
                ) : (
                  <Option style={{ textAlign: "center", color: "grey" }}>
                    Type to Search
                  </Option>
                )}
              </Select>
            </Col>
          </div>
        </div>
      </Header>
      {backButtun}
      <div
        style={{
          margin: windowWidth >= 980 ? "0 auto" : "0px",
          maxWidth: pathArray[3]
            ? "initial"
            : windowWidth >= 980
            ? "980px"
            : "initial",
          margin: pathArray[3] ? "initial" : "50px auto",
        }}
      >
        <main
          style={{
            display: "flex",
          }}
        >
          {Content.children}
        </main>
      </div>
      <Footer style={{ bottom: 0, height: "200px", marginTop: "100px" }}>
        <div className="mr-10">
          <Row
            gutter={[0, 16]}
            style={{ padding: "24px" }}
            className="text_row"
          >
            <Col
              lg={12}
              md={24}
              sm={24}
              xs={24}
              style={{
                textAlign: windowWidth >= 980 ? "initial" : "center",
              }}
            >
              <h1 style={{ fontWeight: 800, letterSpacing: 0 }}>
                Not finding what you are looking for?
              </h1>
              <p className="line_css">Chat with us or send us an email.</p>
            </Col>

            <Col style={{ marginTop: "1px" }} lg={12} md={24} sm={24} xs={24}>
              <Row gutter={[10, 10]} className="button">
                <Col>
                  <button className="chatbtn" onClick={openChatBox}>
                    <CommentOutlined className="btn" />
                    Chat with us
                  </button>
                </Col>

                <Col>
                  <button className="chatbtn" onClick={openMAil}>
                    <MailOutlined className="btn" />
                    Send us an email
                  </button>
                </Col>
              </Row>
            </Col>
            <Divider />
            <div className="">
              <span className="footer-text">© 2023 Posease User Guide</span>
            </div>
          </Row>
        </div>
      </Footer>
    </Layout>
  );
}

export default HFLayout;
